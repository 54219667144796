import React from "react";
import mailbgr from "../assets/mailbgr.gif";
import { FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const Contactus = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "What is your return policy?",
      answer: "Our return policy is 30 days, no questions asked.",
    },
    {
      question: "Do you offer international shipping?",
      answer: "Yes, we ship worldwide with additional charges.",
    },
    {
      question: "How can I track my order?",
      answer: "Once your order is shipped, you will receive a tracking link.",
    },
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <>
      <div className="-z-50 relative px-10 mt-16 mb-10 w-full max-w-screen-xl mx-auto bg-gray-100 shadow-lg rounded-lg overflow-hidden border-white/80 bg-opacity-80 backdrop-blur-2xl backdrop-saturate-200">
        <div>
          <h2 className="pt-6 text-4xl font-bold text-center mb-4 text-sky-600 font-serif md:font-serif">
            Contact Us
          </h2>
          <p className="text-lg text-center mb-8 text-gray-800 mt-4">
            Get a free quote or learn more about our services by contacting
            PickupXpress.com today. We're committed to providing exceptional
            customer service and exceeding your expectations.
          </p>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="mb-10 bg-white py-6 rounded-lg shadow-lg items-center text-center transform hover:scale-105 transition-transform duration-300 flex-col lg:flex-row gap-8">
            <div className="flex-1 my-8 lg:mt-0">
              <form className="px-4 md:px-16">
                <label className="flex flex-row font-semibold text-sky-800 mb-4">
                  Name <span className="text-red-600">*</span>
                </label>
                <input
                  className="pl-4 flex-1 h-11 w-full border border-sky-600 rounded"
                  type="text"
                  placeholder="Full Name"
                />

                <label className="mt-8 mb-4 flex flex-row font-semibold text-sky-800">
                  Email <span className="text-red-600">*</span>
                </label>
                <input
                  className="pl-4 flex-1 h-11 w-full border border-sky-600 rounded"
                  type="email"
                  placeholder="Email Address"
                />

                <label className="mt-8 flex flex-row font-semibold text-sky-800 mb-4">
                  Message <span className="text-red-600">*</span>
                </label>
                <textarea
                  className="pl-4 flex-1 h-32 w-full border border-sky-600 rounded"
                  placeholder="Your Message Here..."
                ></textarea>

                <button className="mt-10" onClick={(e) => e.preventDefault()}>
                  {/* Contact Us */}
                  <a
                    href="mailto:sales@pickupxpress.com"
                    className="bg-sky-400 text-white py-2 px-4 rounded-lg shadow-lg hover:bg-sky-700"
                  >
                    Contact Us
                  </a>
                </button>
              </form>
            </div>
          </div>
          <img
            className="top-0 h-full object-cover mr-auto items-center text-center"
            src={mailbgr}
            alt="Contact Us Gif"
          />
        </div>
      </div>
      <div className="w-full mb-10 max-w-screen-xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden border-white/80 bg-opacity-80 backdrop-blur-2xl backdrop-saturate-200 py-12">
        <div className="flex flex-col mb-10">
          <div className="text-sky-600 font-medium text-2xl justify-center">
            <p className="text-center">Business Hours</p>
          </div>

          <p className="text-sky-700 font-medium text-base mt-4 flex justify-center">
            Monday to Friday:{" "}
            <span className="font-normal">&nbsp;10:00 a.m. - 6:30 p.m.</span>
          </p>
          <p className="text-sky-700 font-medium text-base mt-2 flex justify-center">
            Saturday:{" "}
            <span className="font-normal">
              &nbsp;10:00 a.m. - 5:00 p.m. IST
            </span>
          </p>
          <p className="text-sky-700 font-medium text-base mt-2 flex justify-center">
            Sunday: <span className="font-normal"> &nbsp;closed</span>
          </p>
        </div>
        <div className="container mx-auto px-6">
          <div className="grid grid-cols-2 sm:grid-cols-1 lg:grid-cols-3 gap-6">
            <div className="flex flex-col items-center bg-sky-600 hover:bg-sky-800 p-6 rounded-lg shadow-lg text-white font-medium text-lg">
              <div className="flex-row text-2xl px-1 text-center pb-3">
                <FaLocationDot />
              </div>
              Address
              <p className="text-white font-normal text-base mt-4">
                C-702, Ithum Tower
              </p>
              <p className="text-white font-normal text-base">
                Block C, Industrial Area, Sector 62
              </p>
              <p className="text-white font-normal text-base">
                Noida, Uttar Pradesh-201309
              </p>
            </div>
            <div className="flex flex-col items-center bg-sky-600 hover:bg-sky-800 p-6 rounded-lg shadow-lg text-white font-medium text-lg">
              <div className="flex-row text-2xl px-1 text-center pb-3">
                <MdEmail />
              </div>
              Email
              <p className="text-white font-normal text-base mt-4">
                sales@pickupxpress.com
              </p>
            </div>
            <div className="flex flex-col items-center bg-sky-600 hover:bg-sky-800 p-6 rounded-lg shadow-lg text-white font-medium text-lg">
              <div className="flex-row text-xl px-1 text-center pb-4">
                <FaPhone />
              </div>
              Phone Number
              <p className="text-white font-normal text-base mt-4">
                (0120)-(42833398)
              </p>
              <p className="text-white font-normal text-base">+91 8707000087</p>
            </div>
          </div>
        </div>
      </div>
      <div id="faq-section">
        <div className="-z-50 relative px-10 mt-12 mb-10 w-full max-w-screen-xl mx-auto bg-gray-100 shadow-lg rounded-lg overflow-hidden border-white/80 bg-opacity-80 backdrop-blur-2xl backdrop-saturate-200">
          <h2 className="pt-6 text-4xl font-bold text-center mb-4 text-sky-600">
            Frequently Asked Questions
          </h2>
          <p className="text-lg text-center mb-8 text-gray-800 mt-4">
            We're here to help around the clock, offering 24/7 support for all
            your needs. Explore our FAQs for quick answers, or reach out to us
            anytime for further assistance.
          </p>
          <ul className="space-y-4 text-gray-700">
            {faqs.map((faq, index) => (
              <li key={index}>
                <div
                  className="cursor-pointer text-lg mb-4 card bg-white rounded-lg shadow-lg p-4 transform hover:scale-105 transition-transform duration-300 flex justify-between items-center"
                  onClick={() => toggleFAQ(index)}
                >
                  <span>{faq.question}</span>
                  {activeIndex === index ? <FaChevronUp /> : <FaChevronDown />}
                </div>
                {activeIndex === index && (
                  <div className="p-4 text-gray-600 bg-gray-50 border-l-2 border-sky-600 rounded-lg transition-opacity duration-300 ease-in-out">
                    {faq.answer}
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Contactus;

