import React from "react";
import { FaShippingFast, FaRegHandshake, FaMoneyBillAlt } from "react-icons/fa";
import { MdOutlineError } from "react-icons/md";
import refund from "../assets/refund.gif";

const Refundpolicy = () => {
  return (
    <div
      className="-z-50 relative block w-full mt-3 max-w-screen-xl px-4  mx-auto text-black bg-white border shadow-md 
  rounded-xl border-white/80 bg-opacity-80 backdrop-blur-2xl backdrop-saturate-200 lg:px-8 lg:py-4 py-12"
    >
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-bold text-center mb-6 text-sky-600">
          Refund Policy
        </h2>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 ">
          <div>
            <p className="mb-4 text-lg text-gray-700 text-justify mt-6">
              At PickupXpress, we prioritize the satisfaction of our customers
              and aim to provide reliable and efficient courier services.
              However, we understand that sometimes things may not go as
              planned. This Refund Policy outlines the conditions under which
              you may request a refund, and how we handle such requests to
              ensure a smooth and transparent process.
            </p>

            <div className="bg-white shadow-lg rounded-lg p-4 card mb-4">
              <h3 className="text-3xl font-semibold mb-6 text-gray-800 card mt-4 ">
                Eligibility for Refunds
              </h3>
              <ul className="space-y-4 text-gray-700">
                <li className="flex items-start space-x-3 gap-3">
                  <FaShippingFast className="text-cyan-600 w-8 h-8" />
                  Service Failure: Full or partial refund for lost, damaged, or
                  incorrectly handled parcels.
                </li>
                <li className="flex items-start space-x-3 gap-3">
                  <FaMoneyBillAlt className="text-cyan-600 w-8 h-8 " />
                  Delay Beyond Commitment: Refund for significant delays under
                  our control.
                </li>
                <li className="flex items-start space-x-3 gap-3">
                  <FaRegHandshake className="text-cyan-600 w-8 h-8" />
                  Duplicate Payment: Refund for any duplicate charges.
                </li>
              </ul>
            </div>
            <div className="bg-white shadow-lg rounded-lg p-4 card mb-4">
              <h3 className="text-2xl font-semibold  mb-6 text-gray-800">
                Non-Refundable Cases
              </h3>
              <ul className="space-y-2 text-gray-700">
                <li className="flex items-center">
                  <MdOutlineError className="text-cyan-600 w-6 h-6 mr-3" />
                  Customer Error: Incorrect information leading to delivery
                  failure.
                </li>
                <li className="flex items-center">
                  <MdOutlineError className="text-cyan-600 w-6 h-6 mr-3" />
                  Force Majeure: Delays or non-delivery due to external factors.
                </li>
                <li className="flex items-center">
                  <MdOutlineError className="text-cyan-600 w-6 h-6 mr-3" />
                  Non-Refundable Fees: Handling, insurance, and customs charges.
                </li>
              </ul>
            </div>
            <div className="bg-white shadow-lg rounded-lg p-4 card mb-6">
              <h3 className="text-2xl font-semibold  mb-4 text-gray-800">
                Refund Process
              </h3>
              <p className="mb-4 text-lg text-gray-700">
                Contact our customer service team within 7 days of the expected
                delivery date. Provide your tracking number and details of the
                issue encountered. We will investigate and process approved
                refunds within 5-7 business days.
              </p>
            </div>
          </div>

          {/* Right Section: Images and Icons */}
          <div className="flex flex-col space-y-8">
            <div className="flex items-center justify-center">
              <img
                src={refund}
                alt="Express Parcels"
                className="max-w-full max-h-full p-2 "
              />
            </div>

            <div className="bg-sky-600 p-6 rounded-lg shadow-lg shadow-gray-100">
              <h4 className="text-2xl font-semibold text-white"> Need Help?</h4>
              <p className="text-gray-100 mt-2">
                Our customer support team is available 24/7 to assist you with
                your refund requests. Contact us today at:
              </p>
              <p className="text-gray-100 font-semibold mt-4">
                support@pickupxpress.com
              </p>
              <p className="text-gray-100 mt-1">
                C-702 Ithum Towers Sector 62 Noida
              </p>
              <p className="text-gray-100 mt-1">+91 (870) 70-00087</p>
            </div>

            <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
              <h4 className="text-xl font-semibold text-gray-800">
                Important Information
              </h4>
              <p className="text-gray-800 mt-2">
                Please review our full refund policy before submitting your
                request. Refunds may take up to 10 business days to process.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Refundpolicy;
