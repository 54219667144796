import React from "react";
import { FaShippingFast, FaRegHandshake, FaMoneyBillAlt } from "react-icons/fa";
import accessibility from "../assets/accessibility.gif";
import { VscActivateBreakpoints } from "react-icons/vsc";

const Accessibility = () => {
  return (
    <div className="w-full mt-12 max-w-screen-xl px-4 mx-auto bg-white border border-gray-200 shadow-lg rounded-xl lg:px-8 lg:py-8 py-6">
      <div className="container mx-auto px-6">
        <h2 className="text-5xl font-bold text-center mb-8 text-sky-600">
          Accessibility
        </h2>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          <div>
            <p className="mb-6 text-lg text-gray-700 text-justify">
              At PICKUPXPRESS, we are committed to ensuring that our services,
              website, and digital platforms are accessible to all individuals,
              including those with disabilities. We believe in inclusivity and
              strive to create an environment where everyone can access and
              benefit from our offerings, regardless of their physical abilities
              or technological constraints.
            </p>

            <div className="bg-white shadow-lg rounded-lg p-4 card mb-6">
              <h3 className="text-3xl font-semibold mb-6 text-gray-800 card mt-4 ">
                Our Commitment
              </h3>
              <ul className="space-y-4 text-gray-700">
                <li className="flex items-start space-x-3">
                  <FaShippingFast className="text-cyan-600 w-20 h-8" />
                  <span>
                    <strong>Inclusivity:</strong> We are dedicated to providing
                    an inclusive experience for all users. We aim to make our
                    website, mobile applications, and services accessible to
                    people with varying abilities by following recognized
                    accessibility standards.
                  </span>
                </li>
                <li className="flex items-start space-x-3">
                  <FaMoneyBillAlt className="text-cyan-600 w-16 h-8" />
                  <span>
                    <strong>Compliance:</strong> We are committed to complying
                    with relevant accessibility laws and guidelines, including
                    the Web Content Accessibility Guidelines (WCAG) 2.1 and
                    other applicable accessibility regulations.
                  </span>
                </li>
                <li className="flex items-start space-x-3">
                  <FaRegHandshake className="text-cyan-600 w-16 h-8" />
                  <span>
                    <strong>Continuous Improvement:</strong> Accessibility is an
                    ongoing process. We continuously evaluate and improve our
                    digital platforms to enhance accessibility for all users.
                  </span>
                </li>
              </ul>
            </div>
            <div className="bg-white shadow-lg rounded-lg p-4 card mb-6">
              <h3 className="text-3xl font-semibold mt-8 mb-6 text-gray-800">
                Website Accessibility
              </h3>
              <p className="mb-4">
                To make our website more accessible, we have implemented several
                measures:
              </p>
              <ul className="space-y-4 text-gray-700">
                <li className="flex items-start space-x-3">
                  <VscActivateBreakpoints className="text-cyan-600 w-16 h-8" />
                  <span>
                    <strong>Navigation:</strong> Our website is designed to be
                    navigable using a keyboard, screen readers, and other
                    assistive technologies. We provide clear and consistent
                    navigation menus to help users find information easily.
                  </span>
                </li>
                <li className="flex items-start space-x-3">
                  <VscActivateBreakpoints className="text-cyan-600 w-16 h-8" />
                  <span>
                    <strong>Text Alternatives:</strong> We provide text
                    alternatives for non-text content, such as images and
                    videos, so that they can be understood by screen readers and
                    other assistive devices.
                  </span>
                </li>
                <li className="flex items-start space-x-3">
                  <VscActivateBreakpoints className="text-cyan-600 w-16 h-8" />
                  <span>
                    <strong>Readable Content:</strong> We use clear and simple
                    language across our website to make the content more
                    understandable. We also ensure sufficient color contrast to
                    improve text readability for users with visual impairments.
                  </span>
                </li>
                <li className="flex items-start space-x-3">
                  <VscActivateBreakpoints className="text-cyan-600 w-16 h-8" />
                  <span>
                    <strong>Scalable Text:</strong> Users can resize the text on
                    our website using browser settings without losing content or
                    functionality, making it easier for those with visual
                    impairments to read the information.
                  </span>
                </li>
                <li className="flex items-start space-x-3">
                  <VscActivateBreakpoints className="text-cyan-600 w-16 h-8" />
                  <span>
                    <strong>Accessible Forms:</strong> All forms on our website
                    are designed to be accessible, with clear labels,
                    instructions, and error messages to guide users through the
                    process.
                  </span>
                </li>
              </ul>
            </div>
            <div className="bg-white shadow-lg rounded-lg p-4 card max-w-max  ">
              <div className="bg-gray-100 shadow-lg rounded-lg p-6 card ">
                <h3 className="text-3xl font-semibold mt-8 mb-6 text-gray-800">
                  Training and Awareness
                </h3>
                <p className="mb-4 text-lg text-gray-700">
                  We provide training and resources to our employees to ensure
                  they are aware of accessibility best practices. By raising
                  awareness, we aim to create a culture of inclusivity and
                  ensure that accessibility is considered in all aspects of our
                  operations.
                </p>
              </div>

              <div className="bg-gray-100 shadow-lg rounded-lg p-6 card mt-6">
                <h3 className="text-3xl font-semibold mt-8 mb-6 text-gray-800">
                  Continuous Improvement
                </h3>
                <p className="mb-4 text-lg text-gray-700">
                  Accessibility is an ongoing commitment. We regularly review
                  our website, mobile applications, and services to identify and
                  address accessibility issues. We use feedback from users and
                  advancements in accessibility standards to guide our
                  improvements.
                </p>
              </div>
            </div>
          </div>

          {/* Right Section: Images and Icons */}
          <div className="flex flex-col space-y-20">
            <div className="flex items-center justify-center">
              {/* <FaMoneyBillAlt className="text-cyan-600 w-32 h-32" /> */}
              <img
                src={accessibility}
                alt="Express Parcels"
                className="max-w-full max-h-full p-2 "
              />
            </div>
            <div className="bg-white shadow-lg rounded-lg p-4 card max-w-max ">
              <div className="bg-gray-100 shadow-lg rounded-lg p-6 card ">
                <h3 className="text-3xl font-semibold mt-3 mb-6 text-gray-800">
                  Digital Platforms and Services
                </h3>
                <p className="mb-4 text-lg text-gray-700">
                  In addition to our website, we strive to make our mobile
                  applications and digital services accessible:
                </p>
                <ul className="space-y-4 text-gray-700">
                  <li className="flex items-start space-x-3">
                    <VscActivateBreakpoints className="text-cyan-600 w-16 h-8" />
                    <span>
                      <strong>Mobile Accessibility:</strong> Our mobile
                      applications are designed with accessibility features to
                      support users who rely on screen readers, voice commands,
                      and other assistive technologies.
                    </span>
                  </li>
                  <li className="flex items-start space-x-3">
                    <VscActivateBreakpoints className="text-cyan-600 w-16 h-8" />
                    <span>
                      <strong>Online Booking:</strong> We offer accessible
                      online booking and service request processes, ensuring
                      that users with disabilities can easily access and use our
                      services.
                    </span>
                  </li>
                </ul>
              </div>
              <div className="bg-gray-100 shadow-lg rounded-lg p-6 card  max-w-full mt-6">
                <h3 className="text-3xl font-semibold mt-3 mb-6 text-gray-800">
                  Customer Support
                </h3>
                <p className="mb-4 text-lg text-gray-700">
                  We provide various ways for users to contact us for support:
                </p>
                <ul className="space-y-4 text-gray-700">
                  <li className="flex items-start space-x-3">
                    <VscActivateBreakpoints className="text-cyan-600 w-16 h-8" />
                    <span>
                      <strong>Assistance:</strong> Our customer support team is
                      available to assist users who may encounter accessibility
                      issues or have questions about using our services. We
                      offer multiple contact methods, including phone, email,
                      and online chat.
                    </span>
                  </li>
                  <li className="flex items-start space-x-3">
                    <VscActivateBreakpoints className="text-cyan-600 w-16 h-8" />
                    <span>
                      <strong>Feedback:</strong> We welcome feedback on our
                      accessibility efforts. If you encounter any barriers or
                      have suggestions for improvement, please contact us so we
                      can address the issue promptly.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="bg-sky-600 p-6 rounded-lg shadow-lg shadow-gray-100">
              <h4 className="text-2xl font-semibold text-white">Contact Us</h4>
              <p className="text-gray-100 mt-2">
                If you have questions about our Accessibility Policy, need
                assistance, or would like to provide feedback, please contact us
                at:
              </p>
              <p className="text-gray-100 font-semibold mt-4">
                support@pickupxpress.com
              </p>
              <p className="text-gray-100 mt-1">
                C-702 Ithum Towers Sector 62 Noida
              </p>
              <p className="text-gray-100 mt-1">+91 (870) 70-00087</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <p className="text-gray-800 font-semibold mt-2">
                PICKUPXPRESS is committed to providing an accessible and
                inclusive experience for all users. We will continue to make
                improvements to ensure that our services are usable by everyone,
                regardless of their abilities.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accessibility;
