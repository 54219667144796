import React, { useState } from "react";
import fast from "../assets/fast.png";
import time from "../assets/time.png";
import box from "../assets/box.png";
import man from "../assets/man.png";
import money from "../assets/money.png";
import { VscActivateBreakpoints } from "react-icons/vsc";
import ltl from "../assets/ltl.gif";

const Truckload = () => {
  return (
    <>
      <div className="bg-white py-12">
        <div className="container mx-auto px-6">
          <h2 className=" text-4xl font-bold text-center mb-14 text-gray-700">
            Full Truckload (FTL) Shipping{" "}
          </h2>

          <div className=" max-w-7xl mx-auto">
            <div className="flex flex-col lg:flex-row items-center">
              <div className=" card bg-white w-full lg:w-1/2 lg:h-96 flex justify-center text-center mb-6 lg:mb-0 rounded-lg shadow-lg">
                <img
                  src={ltl}
                  alt="Express Parcels"
                  className="max-w-full max-h-full p-2 "
                />
              </div>

              <div className="w-full lg:w-1/2 lg:pl-12">
                <p className="text-gray-700 mb-6 text-lg">
                  Full Truckload (FTL) shipping involves using an entire truck
                  for a single shipment. This method is ideal for large
                  shipments that can fill a truck's capacity or when a shipper
                  needs a dedicated truck to ensure faster and more secure
                  delivery. Unlike Less Than Truckload (LTL) shipping, where
                  multiple shipments share space in one truck, FTL provides
                  exclusive use of the truck, often resulting in quicker and
                  more direct transit.
                </p>
              </div>
            </div>
            <section className="bg-white rounded-lg shadow-lg p-6 mb-8 mt-10 ">
              <div className=" py-12 bg-white-100 ">
                <div className="container mx-auto px-4">
                  <h1 className="text-3xl font-bold text-center mb-8 text-gray-700">
                    {" "}
                    Key Features of Full Truckload Shipping{" "}
                  </h1>
                </div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 text-center">
                <div className="relative group">
                  <div className="card bg-white rounded-lg shadow-lg ">
                    <div className=" text-white p-4 inline-block">
                      <img src={fast} alt="Fast delivery" className="w-16" />{" "}
                      <p className="text-lg font-semibold mt-2 text-sky-600">
                        Dedicated Transport{" "}
                      </p>
                    </div>
                  </div>
                  <span className="z-50 font-semibold hidden group-hover:block absolute top-28 left-0 right-0 text-gray-800 bg-gray-100 shadow-lg p-4 rounded-lg">
                    FTL shipping dedicates the whole truck to a single shipment,
                    reducing the risks of damage and theft since the cargo is
                    not mixed with other shipments and handled less frequently.
                  </span>
                </div>
                <div className="relative group">
                  <div className="card bg-white rounded-lg shadow-lg ">
                    <div className=" text-white p-4 inline-block">
                      <img src={box} alt="Fast delivery" className="w-16 " />{" "}
                      <p className="text-lg font-semibold mt-2 text-sky-600">
                        Suitable for Large Shipments{" "}
                      </p>
                    </div>
                  </div>
                  <span className="z-50  font-semibold hidden group-hover:block absolute top-28 left-0 right-0 text-gray-800 bg-gray-100 shadow-lg p-4 rounded-lg">
                    FTL is typically used for shipments that weigh between
                    10,000 and 14000 kg or are large enough to fill the truck.
                    This makes it ideal for transporting bulk quantities of
                    goods.
                  </span>
                </div>
                <div className="relative group">
                  <div className="card bg-white rounded-lg shadow-lg ">
                    <div className=" text-white p-4 inline-block">
                      <img src={time} alt="Fast delivery" className="w-16 " />{" "}
                      <p className="text-lg font-semibold mt-2 text-sky-600">
                        Faster Delivery{" "}
                      </p>
                    </div>
                  </div>
                  <span className="z-50  font-semibold hidden group-hover:block absolute top-28 left-0 right-0 text-gray-800 bg-gray-100 shadow-lg p-4 rounded-lg">
                    Since the truck moves directly from the pickup location to
                    the destination without multiple stops for loading and
                    unloading, FTL shipments usually reach their destination
                    faster than LTL shipments.
                  </span>
                </div>
                <div className="relative group">
                  <div className="card bg-white rounded-lg shadow-lg ">
                    <div className=" text-white p-4 inline-block">
                      <img
                        src={money}
                        alt="location delivery"
                        className="w-16 "
                      />{" "}
                      <p className="text-lg font-semibold mt-2 text-sky-600">
                        Cost-Effectiveness for Large Loads{" "}
                      </p>
                    </div>
                  </div>
                  <span className="z-50  font-semibold hidden group-hover:block absolute top-28 left-0 right-0 text-gray-800 bg-gray-100 shadow-lg p-4 rounded-lg">
                    While FTL can be more expensive for smaller shipments, it
                    can be more economical for larger ones as the cost per unit
                    of weight decreases when the truck's full capacity is
                    utilized.
                  </span>
                </div>
                <div className="relative group">
                  <div className="card bg-white rounded-lg shadow-lg ">
                    <div className=" text-white p-4 inline-block">
                      <img src={man} alt="Fast delivery" className="w-16 " />{" "}
                      <p className="text-lg font-semibold mt-2 text-sky-600">
                        Reduced Handling{" "}
                      </p>
                    </div>
                  </div>
                  <span className="z-50  font-semibold hidden group-hover:block absolute top-28 left-0 right-0 text-gray-800 bg-gray-100 shadow-lg p-4 rounded-lg">
                    Because the cargo is loaded once and transported directly to
                    its destination, there's less handling involved, minimizing
                    the risk of damage during transit.
                  </span>
                </div>
              </div>
            </section>
            <section className="bg-white rounded-lg shadow-lg p-6 mb-8 mt-10 ">
              <h2 className="text-3xl font-semibold text-center text-gray-700 mb-8">
                PickupXpress and Full Truckload Shipping{" "}
              </h2>
              <p className="text-gray-700 mb-6 text-lg text-center">
                PICKUPXPRESS is a logistics and transportation company that
                offers specialized full truckload services. They provide
                reliable FTL solutions designed to meet the specific needs of
                businesses, ensuring that goods are transported safely and
                efficiently. Here’s what makes PICKUPXPRESS a preferred choice
                for FTL shipping
              </p>
              <ul className=" grid grid-cols-1 lg:grid-cols-3 gap-4">
                <li>
                  <div className="text-sm  cursor-pointer group perspective p-4 h-44 w-full">
                    <div className="relative duration-1000 preserve-3d group-hover:my-rotate-y-180 h-full bg-gray-100 rounded-lg shadow-lg">
                      <div className="text-center  absolute backface-hidden h-full w-full flex items-center justify-center">
                        <strong className="text-sky-600 text-lg">
                          Modern Fleet{" "}
                        </strong>
                      </div>
                      <div className="absolute my-rotate-y-180 backface-hidden h-full w-full bg-sky-600 shadow-lg rounded-md text-white flex items-center justify-center p-4 overflow-hidden">
                        PICKUPXPRESS operates a fleet of trucks equipped with
                        advanced tracking systems. This allows customers to
                        monitor their shipments in real-time and provides
                        assurance that their cargo is secure.
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="text-sm  cursor-pointer group perspective p-4 h-44 w-full">
                    <div className="relative duration-1000 preserve-3d group-hover:my-rotate-y-180 h-full bg-gray-100 rounded-lg shadow-lg">
                      <div className="text-center  absolute backface-hidden h-full w-full flex items-center justify-center">
                        <strong className="text-sky-600 text-lg">
                          Tailored Shipping Solutions{" "}
                        </strong>
                      </div>
                      <div className="absolute my-rotate-y-180 backface-hidden h-full w-full bg-sky-600 shadow-lg rounded-md text-white flex items-center justify-center p-4 overflow-hidden">
                        PICKUPXPRESS works closely with businesses to customize
                        FTL solutions based on the nature of the cargo, the best
                        route, and the delivery schedule. This personalized
                        approach ensures optimal use of resources and timely
                        delivery.
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="text-sm  cursor-pointer group perspective p-4 h-44 w-full">
                    <div className="relative duration-1000 preserve-3d group-hover:my-rotate-y-180 h-full bg-gray-100 rounded-lg shadow-lg">
                      <div className="text-center  absolute backface-hidden h-full w-full flex items-center justify-center">
                        <strong className="text-sky-600 text-lg">
                          Professional Drivers{" "}
                        </strong>
                      </div>
                      <div className="absolute my-rotate-y-180 backface-hidden h-full w-full bg-sky-600 shadow-lg rounded-md text-white flex items-center justify-center p-4 overflow-hidden">
                        The company employs experienced drivers trained to
                        handle various types of cargo, including delicate and
                        high-value goods. This expertise ensures that shipments
                        are handled with care throughout the journey.
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="text-sm  cursor-pointer group perspective p-4 h-44 w-full">
                    <div className="relative duration-1000 preserve-3d group-hover:my-rotate-y-180 h-full bg-gray-100 rounded-lg shadow-lg">
                      <div className="text-center  absolute backface-hidden h-full w-full flex items-center justify-center">
                        <strong className="text-sky-600 text-lg">
                          On-Time Delivery{" "}
                        </strong>
                      </div>
                      <div className="absolute my-rotate-y-180 backface-hidden h-full w-full bg-sky-600 shadow-lg rounded-md text-white flex items-center justify-center p-4 overflow-hidden">
                        PICKUPXPRESS prioritizes punctuality, using direct
                        routes and minimizing stops to ensure that shipments
                        arrive on schedule. This helps businesses maintain
                        efficient supply chains and meet customer expectations.
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="text-sm  cursor-pointer group perspective p-4 h-44 w-full">
                    <div className="relative duration-1000 preserve-3d group-hover:my-rotate-y-180 h-full bg-gray-100 rounded-lg shadow-lg">
                      <div className="text-center  absolute backface-hidden h-full w-full flex items-center justify-center">
                        <strong className="text-sky-600 text-lg">
                          Competitive Pricing{" "}
                        </strong>
                      </div>
                      <div className="absolute my-rotate-y-180 backface-hidden h-full w-full bg-sky-600 shadow-lg rounded-md text-white flex items-center justify-center p-4 overflow-hidden">
                        For large shipments, PICKUPXPRESS offers cost-effective
                        pricing. By maximizing truck capacity and offering
                        direct transport, they help businesses reduce the
                        overall shipping cost per unit of goods.
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="text-sm  cursor-pointer group perspective p-4 h-44 w-full">
                    <div className="relative duration-1000 preserve-3d group-hover:my-rotate-y-180 h-full bg-gray-100 rounded-lg shadow-lg">
                      <div className="text-center  absolute backface-hidden h-full w-full flex items-center justify-center">
                        <strong className="text-sky-600 text-lg">
                          Focus on Safety{" "}
                        </strong>
                      </div>
                      <div className="absolute my-rotate-y-180 backface-hidden h-full w-full bg-sky-600 shadow-lg rounded-md text-white flex items-center justify-center p-4 overflow-hidden">
                        PICKUPXPRESS adheres to strict safety standards. Their
                        trucks are well-maintained, and their drivers follow all
                        necessary regulations to ensure safe transport,
                        providing peace of mind to businesses.
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </section>

            <section className="bg-sky-600 rounded-lg shadow-lg p-6 text-white">
              <h2 className="text-3xl font-semibold mb-4">
                Contact PickupXpress Today
              </h2>
              <p className="text-lg mb-4">
                Ready to ship with <strong>PICKUPXPRESS FTL Shipping ?</strong>{" "}
                Contact us today for a personalized quote or to learn more about
                how our FTL services can meet your shipping needs. Our team is
                here to help you find the best shipping solution for your
                business.
              </p>

              <a
                href="/Contactus"
                className="bg-white text-sky-600 px-4 py-2 rounded-lg font-semibold hover:bg-gray-200 transition duration-300"
              >
                Contact Us Today
              </a>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default Truckload;
