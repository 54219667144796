import React from 'react';
import { FaBalanceScaleLeft } from "react-icons/fa";
import { RiCustomerService2Line } from "react-icons/ri";
import { GiReturnArrow } from "react-icons/gi";
import { FaBoxOpen } from "react-icons/fa";

const services = [ 
  {
    title: 'Weight Discripncy',
    description: 'Our Effective weight discrepancy management ensures accurate pricing, reduces disputes.',
    icon: <FaBalanceScaleLeft className="w-12 h-12 text-sky-600" />,
    link: '/b2c-orders',
  },
  {
    title: 'NDR Managment',
    description: 'Our easy process includes identifying the reason for non-delivered shipments.',
    icon: <FaBoxOpen className="w-12 h-12 text-sky-600" />,
    link: '/d2c-orders',
  },
  {
    title: 'RTO Managment',
    description: 'Our Effective RTO management helps businesses minimize costs, reduce delays, and improve customer satisfaction.',
    icon: <GiReturnArrow className="w-12 h-12 text-sky-600" />,
    link: '/personal-couriers',
  },
  {
    title: 'Dedicated KAM',
    description: 'Specialized KAM provide tailors solutions, coordinates resources, and proactively addresses any issuess.',
    icon: <RiCustomerService2Line className="w-12 h-12 text-sky-600" />,
    link: '/b2b-orders',
  },
];

const CardCustomf = () => {
  return (
    <div className="w-full mt-10 max-w-screen-xl mx-auto bg-gray-100 shadow-lg rounded-lg overflow-hidden flex 
    flex-col lg:flex-row border-white/80 bg-opacity-80 backdrop-blur-2xl backdrop-saturate-200 pb-8 pt-6">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold text-center mb-8 text-sky-600">Our Unique Selling Preposition</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-8">
          {services.map((service, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center text-center transform hover:scale-105 transition-transform duration-300"
            >
              {service.icon}
              <h3 className="mt-4 text-xl font-semibold text-gray-800">{service.title}</h3>
              <p className="mt-2 text-gray-600">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CardCustomf;
