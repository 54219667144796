import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Privacypolicy = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setIsOpen(false);
    navigate("/");
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <>
      {isOpen && (
        <div className="max-h-screen fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-75">
          <div className="relative bg-white w-11/12 md:w-8/12 lg:w-6/12 xl:w-4/12 rounded-lg p-8 max-h-screen overflow-y-auto">
            <button
              onClick={handleClose}
              className="absolute top-4 right-4 text-gray-700 hover:text-gray-900"
            >
              &times;
            </button>
            <h2 className="text-3xl font-semibold mb-4 text-center text-sky-600">
              Privacy Policy
            </h2>
            <p className="mb-4">
              PICKUPXPRESS is committed to protecting the privacy and security
              of our customers' personal information. This Privacy Policy
              explains how we collect, use, store, and protect your information
              when you use our services. By accessing or using our services, you
              agree to the terms outlined in this Privacy Policy. We aim to
              handle your data responsibly and ensure that your privacy is
              respected.
            </p>
            <h3 className="font-bold mb-2">1. Information We Collect</h3>
            <p className="mb-4">
              We collect information to provide and enhance our services. This
              may include:
            </p>
            <ul className="list-disc ml-5 mb-4">
              <li>
                Personal Information: Such as your name, contact details (phone
                number, email address), billing and shipping addresses, payment
                information, and company identification details if applicable.
              </li>
              <li>
                Service-Related Information: Details related to the shipments
                you book with us, including pickup and delivery addresses,
                package information, and tracking details.
              </li>
              <li>
                Usage Information: Data on how you interact with our website,
                mobile apps, and services, like IP addresses, browser types, and
                pages visited.
              </li>
              <li>
                Communications: Records of your interactions with us, including
                emails, phone calls, and messages sent through our website or
                app.
              </li>
            </ul>
            <h3 className="font-bold mb-2">2. How We Use Your Information</h3>
            <ul className="list-disc ml-5 mb-4">
              <li>
                Service Delivery: To process and complete your shipping
                requests, manage payments, and provide shipment tracking.
              </li>
              <li>
                Customer Support: To respond to inquiries and provide customer
                service.
              </li>
              <li>
                Service Improvement: To analyze usage patterns and improve our
                services, website, and mobile applications.
              </li>
              <li>
                Marketing and Communication: To send updates, promotions, and
                other information about our services. You can opt out of
                marketing communications at any time.
              </li>
              <li>
                Legal Compliance: To comply with legal obligations, resolve
                disputes, and enforce our agreements.
              </li>
            </ul>
            <h3 className="font-bold mb-2">
              3. Information Sharing and Disclosure
            </h3>
            <p className="mb-4">
              We do not sell or trade your personal information to third
              parties. However, we may share your information under the
              following circumstances:
            </p>
            <ul className="list-disc ml-5 mb-4">
              <li>
                Service Providers: With third-party providers who help us
                operate our business, like payment processors and shipping
                partners. These partners are required to protect your
                information and use it only as needed to provide their services.
              </li>
              <li>
                Legal Requirements: If necessary, to comply with laws,
                regulations, or legal processes, or to protect our rights,
                privacy, safety, or property.
              </li>
              <li>
                Business Transfers: In the event of a merger, acquisition,
                reorganization, or sale of assets, your information may be
                transferred as part of the business deal.
              </li>
            </ul>
            <h3 className="font-bold mb-2">4. Data Security</h3>
            <p className="mb-4">
              We use appropriate security measures to protect your personal
              information from unauthorized access, alteration, disclosure, or
              destruction. These measures include encryption, secure servers,
              and other standard security practices. However, no internet
              transmission or electronic storage is completely secure, so we
              cannot guarantee absolute security.
            </p>
            <h3 className="font-bold mb-2">5. Data Retention</h3>
            <p className="mb-4">
              We keep your personal information only as long as necessary to
              fulfill the purposes for which it was collected, comply with legal
              requirements, resolve disputes, and enforce our agreements. When
              no longer needed, we securely delete or anonymize your
              information.
            </p>
            <h3 className="font-bold mb-2">6. Your Rights</h3>
            <p className="mb-4">
              You may have certain rights regarding your personal information,
              depending on your location. These rights may include:
            </p>
            <ul className="list-disc ml-5 mb-4">
              <li>
                Access: Request access to the personal information we hold about
                you.
              </li>
              <li>
                Correction: Request correction of any inaccurate or incomplete
                information.
              </li>
              <li>
                Deletion: Request deletion of your personal information, subject
                to legal obligations.
              </li>
              <li>
                Objection: Object to the processing of your personal information
                for certain purposes.
              </li>
              <li>Opt-Out: Opt out of receiving marketing communications.</li>
            </ul>
            <h3 className="font-bold mb-2">
              7. Cookies and Tracking Technologies
            </h3>
            <p className="mb-4">
              We use cookies and similar technologies to enhance your experience
              on our website. Cookies help us remember your preferences and
              improve our website's performance. You can control cookies through
              your browser settings, but disabling them may affect website
              functionality.
            </p>
            <h3 className="font-bold mb-2">
              8. Changes to This Privacy Policy
            </h3>
            <p className="mb-4">
              We may update this Privacy Policy occasionally to reflect changes
              in our practices or legal requirements. Any significant changes
              will be communicated by posting the updated Privacy Policy on our
              website with an updated "Last Updated" date. We encourage you to
              review this policy periodically to stay informed.
            </p>
            <div className="card bg-sky-600 shadow-lg rounded-lg p-4">
              <h3 className="text-white text-lg font-bold mb-2">Contact Us</h3>
              <p className="text-base text-white font-bold">
                If you have any questions or concerns about this Privacy Policy
                or our data practices, please contact us at:
              </p>
              <p className="text-base text-white mt-4">
                <strong> Email:</strong> support@pickupepxress.com
              </p>
              <p className="text-base text-white mt-1">
                <strong> Address:</strong> C-702 Ithum Towers Noida Sector
                62-U.P.
              </p>
              <p className="text-base text-white mt-1">
                <strong>Phone:</strong> +91-8707000087
              </p>
            </div>
            <p className="text-base mt-4 flex items-center">
              <input
                id="privacy-checkbox"
                type="checkbox"
                checked={isChecked} // Bind checkbox to state
                onChange={handleCheckboxChange} // Handle change event
                className=" -mt-16 w-14 h-14 text-2xl text-sky-600 bg-gray-100 "
              />
              <label htmlFor="privacy-checkbox" className="ml-2">
                By using PICKUPXPRESS services, you acknowledge that you have
                read and understood this Privacy Policy and agree to the
                collection, use, and sharing of your information as described.
              </label>
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default Privacypolicy;

// const Privacypolicy = () => {
//   const [isOpen, setIsOpen] = useState(true);
//   const navigate = useNavigate();

//   const handleClose = () => {
//     setIsOpen(false);
//     navigate("/");
//   };

//   return (
//     <>
//       {isOpen && (
//         <div className="max-h-screen fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-75 ">
//           <div className="relative bg-white w-11/12 md:w-8/12 lg:w-6/12 xl:w-4/12 rounded-lg p-8 max-h-screen overflow-y-auto">
//             <button
//               onClick={handleClose}
//               className="absolute top-4 right-4 text-gray-700 hover:text-gray-900"
//             >
//               &times;
//             </button>
//             <h2 className="text-3xl font-semibold mb-4 text-center text-sky-600">
//               Privacy Policy
//             </h2>
//             <p className="mb-4">
//               PICKUPXPRESS is committed to protecting the privacy and security
//               of our customers' personal information. This Privacy Policy
//               explains how we collect, use, store, and protect your information
//               when you use our services. By accessing or using our services, you
//               agree to the terms outlined in this Privacy Policy. We aim to
//               handle your data responsibly and ensure that your privacy is
//               respected.
//             </p>
//             <h3 className="font-bold mb-2">1. Information We Collect</h3>
//             <p className="mb-4">
//               We collect information to provide and enhance our services. This
//               may include:
//             </p>
//             <ul className="list-disc ml-5 mb-4">
//               <li>
//                 Personal Information: Such as your name, contact details (phone
//                 number, email address), billing and shipping addresses, payment
//                 information, and company identification details if applicable.
//               </li>
//               <li>
//                 Service-Related Information: Details related to the shipments
//                 you book with us, including pickup and delivery addresses,
//                 package information, and tracking details.
//               </li>
//               <li>
//                 Usage Information: Data on how you interact with our website,
//                 mobile apps, and services, like IP addresses, browser types, and
//                 pages visited.
//               </li>
//               <li>
//                 Communications: Records of your interactions with us, including
//                 emails, phone calls, and messages sent through our website or
//                 app.
//               </li>
//             </ul>
//             <h3 className="font-bold mb-2">2. How We Use Your Information</h3>
//             <ul className="list-disc ml-5 mb-4">
//               <li>
//                 Service Delivery: To process and complete your shipping
//                 requests, manage payments, and provide shipment tracking.
//               </li>
//               <li>
//                 Customer Support: To respond to inquiries and provide customer
//                 service.
//               </li>
//               <li>
//                 Service Improvement: To analyze usage patterns and improve our
//                 services, website, and mobile applications.
//               </li>
//               <li>
//                 Marketing and Communication: To send updates, promotions, and
//                 other information about our services. You can opt out of
//                 marketing communications at any time.
//               </li>
//               <li>
//                 Legal Compliance: To comply with legal obligations, resolve
//                 disputes, and enforce our agreements.
//               </li>
//             </ul>
//             <h3 className="font-bold mb-2">
//               3. Information Sharing and Disclosure
//             </h3>
//             <p className="mb-4">
//               We do not sell or trade your personal information to third
//               parties. However, we may share your information under the
//               following circumstances:
//             </p>
//             <ul className="list-disc ml-5 mb-4">
//               <li>
//                 Service Providers: With third-party providers who help us
//                 operate our business, like payment processors and shipping
//                 partners. These partners are required to protect your
//                 information and use it only as needed to provide their services.
//               </li>
//               <li>
//                 Legal Requirements: If necessary, to comply with laws,
//                 regulations, or legal processes, or to protect our rights,
//                 privacy, safety, or property.
//               </li>
//               <li>
//                 Business Transfers: In the event of a merger, acquisition,
//                 reorganization, or sale of assets, your information may be
//                 transferred as part of the business deal.
//               </li>
//             </ul>
//             <h3 className="font-bold mb-2">4. Data Security</h3>
//             <p className="mb-4">
//               We use appropriate security measures to protect your personal
//               information from unauthorized access, alteration, disclosure, or
//               destruction. These measures include encryption, secure servers,
//               and other standard security practices. However, no internet
//               transmission or electronic storage is completely secure, so we
//               cannot guarantee absolute security.
//             </p>
//             <h3 className="font-bold mb-2">5. Data Retention</h3>
//             <p className="mb-4">
//               We keep your personal information only as long as necessary to
//               fulfill the purposes for which it was collected, comply with legal
//               requirements, resolve disputes, and enforce our agreements. When
//               no longer needed, we securely delete or anonymize your
//               information.
//             </p>
//             <h3 className="font-bold mb-2">6. Your Rights</h3>
//             <p className="mb-4">
//               You may have certain rights regarding your personal information,
//               depending on your location. These rights may include:
//             </p>
//             <ul className="list-disc ml-5 mb-4">
//               <li>
//                 Access: Request access to the personal information we hold about
//                 you.
//               </li>
//               <li>
//                 Correction: Request correction of any inaccurate or incomplete
//                 information.
//               </li>
//               <li>
//                 Deletion: Request deletion of your personal information, subject
//                 to legal obligations.
//               </li>
//               <li>
//                 Objection: Object to the processing of your personal information
//                 for certain purposes.
//               </li>
//               <li>Opt-Out: Opt out of receiving marketing communications.</li>
//             </ul>
//             <h3 className="font-bold mb-2">
//               7. Cookies and Tracking Technologies
//             </h3>
//             <p className="mb-4">
//               We use cookies and similar technologies to enhance your experience
//               on our website. Cookies help us remember your preferences and
//               improve our website's performance. You can control cookies through
//               your browser settings, but disabling them may affect website
//               functionality.
//             </p>
//             <h3 className="font-bold mb-2">
//               8. Changes to This Privacy Policy
//             </h3>
//             <p className="mb-4">
//               We may update this Privacy Policy occasionally to reflect changes
//               in our practices or legal requirements. Any significant changes
//               will be communicated by posting the updated Privacy Policy on our
//               website with an updated "Last Updated" date. We encourage you to
//               review this policy periodically to stay informed.
//             </p>
//             <div className="card  bg-sky-600 shadow-lg rounded-lg p-4">
//               <h3 className="text-white text-lg  font-bold mb-2">Contact Us</h3>
//               <p className="text-base text-white font-bold">
//                 If you have any questions or concerns about this Privacy Policy
//                 or our data practices, please contact us at:
//               </p>
//               <p className="text-base text-white mt-4">
//                 <strong> Email:</strong> support@pickupepxress.com
//               </p>
//               <p className="text-base text-white mt-1">
//                 <strong> Address:</strong> C-702 Ithum Towers Noida Sector
//                 62-U.P.
//               </p>
//               <p className="text-base text-white mt-1">
//                 <strong>Phone:</strong> +91-8707000087
//               </p>
//             </div>
//             <p className=" text-base mt-4">
//               <input
//                 id="default-radio-1"
//                 type="radio"
//                 value=""
//                 name="default-radio"
//                 class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
//               />
//               By using PICKUPXPRESS services, you acknowledge that you have read
//               and understood this Privacy Policy and agree to the collection,
//               use, and sharing of your information as described.
//             </p>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default Privacypolicy;
