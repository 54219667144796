import React from "react";
import { FaShippingFast, FaRegHandshake, FaMoneyBillAlt } from "react-icons/fa";
import accessibility from "../assets/accessibility.gif";
import { VscActivateBreakpoints } from "react-icons/vsc";

const Companyprofile = () => {
  return (
    <div className="w-full mt-12 max-w-screen-xl px-4 mx-auto bg-white border border-gray-200 shadow-lg rounded-xl lg:px-8 lg:py-8 py-6">
      <div className="container mx-auto px-6">
        <h2 className="text-5xl font-bold text-center mb-8 text-sky-600">
          Company Profile{" "}
        </h2>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          <div>
            {/* <p className="mb-6 text-lg text-gray-700 text-justify">
              At PICKUPXPRESS, we are committed to ensuring that our services,
              website, and digital platforms are accessible to all individuals,
              including those with disabilities. We believe in inclusivity and
              strive to create an environment where everyone can access and
              benefit from our offerings, regardless of their physical abilities
              or technological constraints.
            </p> */}

            <div className="bg-white shadow-lg rounded-lg p-4 card mb-6">
              <h3 className="text-3xl font-semibold mb-6 text-gray-800 card mt-4 ">
                Who We Are
              </h3>
              <ul className="space-y-4 text-gray-700">
                <li className="flex items-start space-x-3">
                  <VscActivateBreakpoints className="text-cyan-600 w-8 h-8" />
                  <span>
                    PICKUPXPRESS is an expanding regional logistics company with
                    a commitment to innovation, technology, and service
                    excellence.
                  </span>
                </li>
                <li className="flex items-start space-x-3">
                  <VscActivateBreakpoints className="text-cyan-600 w-8 h-8" />
                  <span>
                    Providing integrated logistics solutions including land
                    freight, air freight, and distribution management.
                  </span>
                </li>
                <li className="flex items-start space-x-3">
                  <VscActivateBreakpoints className="text-cyan-600 w-8 h-8" />
                  <span>
                    Established in 2019, delivering cost-effective solutions
                    tailored to customer needs.
                  </span>
                </li>
                <li className="flex items-start space-x-3">
                  <VscActivateBreakpoints className="text-cyan-600 w-8 h-8" />
                  <span>
                    Staffed by experts from various sectors of the
                    transportation industry.
                  </span>
                </li>
                <li className="flex items-start space-x-3">
                  <VscActivateBreakpoints className="text-cyan-600 w-8 h-8" />
                  <span>
                    We are emerging Ecommerce shipping service provider in
                    India.
                  </span>
                </li>
              </ul>
            </div>
            <div className="bg-white shadow-lg rounded-lg p-4 card mb-6">
              <h3 className="text-3xl font-semibold mt-8 mb-6 text-gray-800">
                Corporate Mission{" "}
              </h3>
              <p className="mb-4">
                To make our website more accessible, we have implemented several
                measures:
              </p>
              <ul className="space-y-4 text-gray-700">
                <li className="flex items-start space-x-3">
                  <VscActivateBreakpoints className="text-cyan-600 w-16 h-8" />
                  <span>
                    <strong>Vision : </strong> To become a leading logistics
                    company and develop sustainably with good corporate
                    governance.
                  </span>
                </li>
                <li className="flex items-start space-x-3">
                  <VscActivateBreakpoints className="text-cyan-600 w-16 h-8" />
                  <span>
                    <strong>Mission : </strong> To provide excellent logistics
                    services to satisfy customer needs.
                  </span>
                </li>
                <li className="flex items-start space-x-3">
                  <VscActivateBreakpoints className="text-cyan-600 w-16 h-8" />
                  <span>
                    <strong>Values : </strong> Quality, Integrity, Reliability,
                    Prosperity, Innovation.
                  </span>
                </li>
              </ul>
            </div>
            <div className="bg-white shadow-lg rounded-lg p-4 card max-w-max  ">
              <div className="bg-gray-100 shadow-lg rounded-lg p-6 card ">
                <h3 className="text-3xl font-semibold mt-8 mb-6 text-gray-800">
                  Problems We Solve{" "}
                </h3>
                <p className="mb-4 text-lg text-gray-700">
                  24x7 customer support. u Minimal weight discrepancy and
                  negligible freight discrepancy. u 100% claim settlement. u
                  Competitive rates to reduce shipping costs. u Door pickup and
                  delivery across 19,000+ pin codes in India.
                </p>
              </div>

              <div className="bg-gray-100 shadow-lg rounded-lg p-6 card mt-6">
                <h3 className="text-3xl font-semibold mt-8 mb-6 text-gray-800">
                What Makes Us Unique                </h3>
                <p className="mb-4 text-lg text-gray-700">
                Dedication to enhancing existing supply chains with a
cost-effective professional approach.
u Long-term relationships with price-sensitive companies.
u Proven track record to deliver any shipment size.
u Pan-India presence ensuring fast, reliable, and secure
solutions.
                </p>
              </div>
            </div>
          </div>

          {/* Right Section: Images and Icons */}
          <div className="flex flex-col space-y-20">
            <div className="flex items-center justify-center">
              {/* <FaMoneyBillAlt className="text-cyan-600 w-32 h-32" /> */}
              <img
                src={accessibility}
                alt="Express Parcels"
                className="max-w-full max-h-full p-2 "
              />
            </div>
            <div className="bg-white shadow-lg rounded-lg p-4 card max-w-max ">
              <div className="bg-gray-100 shadow-lg rounded-lg p-6 card ">
                <h3 className="text-3xl font-semibold mt-3 mb-6 text-gray-800">
                  Digital Platforms and Services
                </h3>
                <p className="mb-4 text-lg text-gray-700">
                  In addition to our website, we strive to make our mobile
                  applications and digital services accessible:
                </p>
                <ul className="space-y-4 text-gray-700">
                  <li className="flex items-start space-x-3">
                    <VscActivateBreakpoints className="text-cyan-600 w-16 h-8" />
                    <span>
                      <strong>Mobile Accessibility:</strong> Our mobile
                      applications are designed with accessibility features to
                      support users who rely on screen readers, voice commands,
                      and other assistive technologies.
                    </span>
                  </li>
                  <li className="flex items-start space-x-3">
                    <VscActivateBreakpoints className="text-cyan-600 w-16 h-8" />
                    <span>
                      <strong>Online Booking:</strong> We offer accessible
                      online booking and service request processes, ensuring
                      that users with disabilities can easily access and use our
                      services.
                    </span>
                  </li>
                </ul>
              </div>
              <div className="bg-gray-100 shadow-lg rounded-lg p-6 card  max-w-full mt-6">
                <h3 className="text-3xl font-semibold mt-3 mb-6 text-gray-800">
                  Customer Support
                </h3>
                <p className="mb-4 text-lg text-gray-700">
                  We provide various ways for users to contact us for support:
                </p>
                <ul className="space-y-4 text-gray-700">
                  <li className="flex items-start space-x-3">
                    <VscActivateBreakpoints className="text-cyan-600 w-16 h-8" />
                    <span>
                      <strong>Assistance:</strong> Our customer support team is
                      available to assist users who may encounter accessibility
                      issues or have questions about using our services. We
                      offer multiple contact methods, including phone, email,
                      and online chat.
                    </span>
                  </li>
                  <li className="flex items-start space-x-3">
                    <VscActivateBreakpoints className="text-cyan-600 w-16 h-8" />
                    <span>
                      <strong>Feedback:</strong> We welcome feedback on our
                      accessibility efforts. If you encounter any barriers or
                      have suggestions for improvement, please contact us so we
                      can address the issue promptly.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="bg-sky-600 p-6 rounded-lg shadow-lg shadow-gray-100">
              <h4 className="text-2xl font-semibold text-white">Contact Us</h4>

              <p className="text-gray-100 font-semibold mt-4">
                Corporate Office: C-702, ithum Tower, Noida Sector 62.
              </p>
              <p className="text-gray-100 mt-1">
                Registered Office: GH-01, Sector 16-B, Greater Noida, Gautam
                Buddha Nagar.{" "}
              </p>
              <p className="text-gray-100 mt-1">
                {" "}
                Contact Number: +91-99114 93609.{" "}
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <p className="text-gray-800 font-semibold mt-2">
                PICKUPXPRESS is committed to providing an accessible and
                inclusive experience for all users. We will continue to make
                improvements to ensure that our services are usable by everyone,
                regardless of their abilities.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Companyprofile;
