import React from "react";
import careers from "../assets/careers.gif";
import { TbPointFilled } from "react-icons/tb";

const Career = () => {
  return (
    <>
      <div className="bg-white py-12">
        <div className="container mx-auto px-6">
          <h2 className=" text-4xl font-bold text-center mb-14 text-gray-800">
            Join our journey of growth and innovation!
          </h2>

          <div className=" max-w-7xl mx-auto">
            <div className="flex flex-col lg:flex-row items-center">
              <div className=" card bg-white w-full lg:w-1/2 lg:h-96 flex justify-center text-center mb-6 lg:mb-0 rounded-lg shadow-lg">
                <img
                  src={careers}
                  alt="Express Parcels"
                  className="max-w-full max-h-full p-2"
                />
              </div>

              <div className="w-full lg:w-1/2 lg:pl-12">
                <p className="text-gray-700 mb-6 text-lg">
                  At PICKUPXPRESS, we are always on the lookout for talented,
                  passionate, and driven individuals to join our growing team.
                  Since our inception in 2020, we've been committed to
                  transforming the logistics and shipping industry through
                  innovation, dedication, and a customer-centric approach. As we
                  continue to expand our services, we are looking for people who
                  share our vision of delivering excellence in logistics.
                </p>
              </div>
            </div>
            <section className="bg-white rounded-lg shadow-lg p-6 mb-8 mt-10 ">
              <h2 className="text-3xl font-semibold text-center text-gray-800 mb-8">
                Why you should work with us?{" "}
              </h2>
              <ul className="space-y-4 text-gray-700 p-4">
                <li>
                  <div className="text-sm card bg-gray-100 rounded-lg shadow-lg p-2 transform hover:scale-105 transition-transform duration-300">
                    <strong>Dynamic Work Environment :</strong> Join a
                    fast-paced and dynamic industry where every day brings new
                    challenges and opportunities. At PICKUPXPRESS, you'll be
                    part of a team that thrives on problem-solving and
                    innovation.
                  </div>
                </li>
                <li>
                  <div className=" text-sm card bg-gray-100 rounded-lg shadow-lg p-2 transform hover:scale-105 transition-transform duration-300">
                    <strong>Growth Opportunities :</strong> We believe in
                    nurturing talent and providing opportunities for
                    professional growth. Whether you're just starting your
                    career or looking to take the next step, we offer a
                    supportive environment where you can learn, grow, and
                    advance.
                  </div>
                </li>
                <li>
                  <div className=" text-sm card bg-gray-100 rounded-lg shadow-lg p-2 transform hover:scale-105 transition-transform duration-300">
                    <strong>Innovative Culture :</strong>We embrace technology
                    and innovation in all aspects of our business. You'll have
                    the chance to work with the latest logistics technologies
                    and contribute to developing solutions that drive efficiency
                    and customer satisfaction.
                  </div>
                </li>
                <li>
                  <div className=" text-sm card bg-gray-100 rounded-lg shadow-lg p-2 transform hover:scale-105 transition-transform duration-300">
                    <strong>Team-Oriented :</strong> Our success is built on
                    collaboration. You'll work alongside a diverse and dedicated
                    team of professionals who are passionate about delivering
                    the best possible service to our clients.
                  </div>
                </li>
                <li>
                  <div className=" text-sm card bg-gray-100 rounded-lg shadow-lg p-2 transform hover:scale-105 transition-transform duration-300">
                    <strong>Customer-Centric Focus :</strong> At PICKUPXPRESS,
                    our clients are at the heart of everything we do. By joining
                    our team, you'll play a key role in helping businesses
                    across various sectors meet their logistics and shipping
                    needs.
                  </div>
                </li>
              </ul>
            </section>
            <div className="bg-white shadow-lg rounded-lg p-4 card max-w-max ">
              <div className="bg-white shadow-lg rounded-lg p-6 card ">
                <h3 className="text-2xl font-bold mt-3 mb-6 text-sky-600">
                  Current Openings{" "}
                </h3>
                <h1 className="text-xl font-bold mb-4">
                  Business Development Manager (BDM) - Corporate Sales (B2B)
                </h1>
                <p className="mb-4">
                  <span className="font-semibold">Location:</span> Noida, India{" "}
                  <br />
                  <span className="font-semibold">Type:</span> Full-Time
                </p>
                <h2 className="text-xl font-semibold mb-2">Role Overview:</h2>
                <p className="mb-4">
                  We are looking for a highly motivated and experienced Business
                  Development Manager (BDM) to join our Corporate Sales (B2B)
                  team. In this role, you will be responsible for driving sales
                  growth by identifying new business opportunities, building
                  strong relationships with corporate clients, and developing
                  strategies to achieve sales targets. The ideal candidate will
                  have a deep understanding of the logistics and shipping
                  industry and a proven track record in B2B sales.
                </p>
                <h2 className="text-xl font-semibold mb-2">
                  Key Responsibilities:
                </h2>
                <ul className="list-disc pl-5 mb-4">
                  <li>
                    Identify and target potential corporate clients to generate
                    new business opportunities.
                  </li>
                  <li>
                    Develop and execute effective sales strategies to achieve
                    revenue targets.
                  </li>
                  <li>
                    Build and maintain strong relationships with key
                    decision-makers in client organizations.
                  </li>
                  <li>
                    Understand client needs and provide tailored logistics
                    solutions that align with their business objectives.
                  </li>
                  <li>
                    Collaborate with internal teams to ensure seamless execution
                    of services and high levels of customer satisfaction.
                  </li>
                  <li>
                    Prepare and deliver compelling sales presentations and
                    proposals.
                  </li>
                  <li>
                    Monitor market trends, competitor activities, and industry
                    developments to stay ahead in the market.
                  </li>
                  <li>
                    Report on sales performance and provide insights to improve
                    sales processes and strategies.
                  </li>
                </ul>
                <h2 className="text-xl font-semibold mb-2">
                  Qualifications and Skills:
                </h2>
                <ul className="list-disc pl-5 mb-4">
                  <li>
                    Bachelor's degree in Business, Marketing, or a related
                    field.
                  </li>
                  <li>
                    Minimum of 3-5 years of experience in B2B sales, preferably
                    in the logistics or shipping industry.
                  </li>
                  <li>
                    Strong understanding of FTL, PTL, e-commerce shipping, train
                    cargo, express parcel, air cargo, and international shipping
                    services.
                  </li>
                  <li>
                    Excellent communication, negotiation, and presentation
                    skills.
                  </li>
                  <li>
                    Proven track record of achieving and exceeding sales
                    targets.
                  </li>
                  <li>
                    Ability to work independently and as part of a team in a
                    fast-paced environment.
                  </li>
                  <li>
                    Strong problem-solving skills and a strategic mindset.
                  </li>
                </ul>
                <h2 className="text-xl font-semibold mb-2">What We Offer:</h2>
                <ul className="list-disc pl-5 mb-4">
                  <li>Competitive salary and commission structure.</li>
                  <li>
                    Comprehensive benefits package, including health insurance
                    and paid time off.
                  </li>
                  <li>
                    Opportunities for professional growth and career
                    advancement.
                  </li>
                  <li>A dynamic and collaborative work environment.</li>
                </ul>
                <h2 className="text-xl font-semibold mb-2">How to Apply:</h2>
                <p className="mb-4">
                  To apply for this position, please send your resume and a
                  cover letter to{" "}
                  <a
                    href="mailto:careers@pickupxpress.com"
                    className="text-blue-500"
                  >
                    careers@pickupxpress.com
                  </a>{" "}
                  with the subject line "Application for BDM - Corporate Sales
                  (B2B)." In your cover letter, tell us why you are the ideal
                  candidate for this role and how your experience aligns with
                  our needs.
                </p>
              </div>
              <div className="bg-white shadow-lg rounded-lg p-6 card  max-w-full mt-6 w-full lg:w-1/2">
                <h3 className="text-2xl font-semibold mt-3 mb-6 text-gray-800">
                  Other Career Opportunities
                </h3>
                <p className="mb-4 text-lg text-gray-700">
                  We also offer various other roles across different areas of
                  our business:
                </p>
                <ul className="space-y-4 text-gray-700">
                  <li className="flex items-start space-x-3">
                    <TbPointFilled className="text-cyan-600 text-sm w-16 h-6" />
                    Logistics Coordinators
                  </li>
                  <li className="flex items-start space-x-3">
                    <TbPointFilled className="text-cyan-600 w-16 h-6" />
                    Customer Support Representatives
                  </li>
                  <li className="flex items-start space-x-3">
                    <TbPointFilled className="text-cyan-600 w-16 h-6" />
                    Warehouse and Operations Staff
                  </li>
                  <li className="flex items-start space-x-3">
                    <TbPointFilled className="text-cyan-600 w-16 h-6" />
                    Sales and Business Development
                  </li>
                  <li className="flex items-start space-x-3">
                    <TbPointFilled className="text-cyan-600 w-16 h-6" />
                    Drivers and Delivery Personnel
                  </li>
                  <li className="flex items-start space-x-3">
                    <TbPointFilled className="text-cyan-600 w-16 h-6" />
                    IT and Tech Specialists
                  </li>
                </ul>
              </div>
            </div>

            <section className="bg-sky-600 rounded-lg shadow-lg p-6 text-white">
              <h2 className="text-3xl font-semibold mb-4">
                Join the PICKUPXPRESS Team
              </h2>
              <p className="text-lg mb-4">
                If you're looking for a challenging and rewarding career in the
                logistics and shipping industry, PICKUPXPRESS is the place for
                you. We are excited to welcome individuals who are ready to make
                a difference and contribute to our mission of delivering
                excellence in logistics.
              </p>

              <a
                href="/Contactus"
                className="bg-white text-sky-600 px-4 py-2 rounded-lg font-semibold hover:bg-gray-200 transition duration-300"
              >
                Contact Us Today
              </a>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};
export default Career;
