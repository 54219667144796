import React from "react";
import { Link } from "react-router-dom";

const services = [
  {
    title: "Express Parcel Services",
    description: "Get your urgent shipments delivered quickly and reliably.",
    icon: "📦",
    link: "/Express",
  },
  {
    title: "Ecommerce Shipping Solutions",
    description:
      "Streamline your online store's fulfillment process with our hassle-free shipping options.",
    icon: "🛒",
    link: "/Ecommerce",
  },
  {
    title: "Import & Export Shipping Management",
    description:
      "Navigate the complexities of international trade with our expert guidance.",
    icon: "🌍",
    link: "/ExportImp",
  },
  {
    title: "Less-than-Truckload (LTL)",
    description: "We offer cost-effective solutions for freight of all sizes.",
    icon: "🚛",
    link: "/Surfaceship",
  },
  {
    title: "Train Transport Services",
    description:
      "Benefit from the reliability and affordability of rail shipping for large shipments.",
    icon: "🚂",
    link: "/Traincargo",
  },
  {
    title: "Full-Truckload (FTL) Shipping",
    description: "We offer cost-effective solutions for freight of all sizes.",
    icon: "🚛",
    link: "/Truckload",
  },
];

const CardSer = () => {
  return (
    <>
      <div
        className="w-full mt-10 max-w-screen-xl mx-auto bg-gray-100 shadow-lg rounded-lg overflow-hidden flex 
    flex-col lg:flex-row border-white/80 bg-opacity-80 backdrop-blur-2xl backdrop-saturate-200"
      >
        <div className="bg-white py-12  ">
          <div className="container mx-auto px-4">
            <h1 className="text-3xl font-bold text-center mb-8">
              Domestic & International Shipping Solutions
            </h1>
            <p className="text-center mb-12">
              PickupXpress.com is your one-stop shop for all your shipping
              needs. We offer a comprehensive suite of services to ensure your
              parcels, ecommerce orders, and cargo reach their destinations
              efficiently and securely.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {services.map((service, index) => (
                <div
                  key={index}
                  className="bg-white p-6 rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
                >
                  <div className="text-4xl text-cyan-600 mb-4">
                    {service.icon}
                  </div>
                  <h2 className="text-xl font-semibold mb-2">
                    {service.title}
                  </h2>
                  <p className="text-gray-700">{service.description}</p>
                  <div className="mt-2">
                    <Link
                      to={service.link}
                      className="mt-4 text-cyan-600 hover:text-cyan-800 transition-colors duration-300"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
              ))}
            </div>
            <div className="text-center mt-12">
              <p className="text-lg font-semibold mb-4">Contact Us Today</p>
              <p className="mb-8">
                Get a free quote or learn more about our services by contacting
                PickupXpress.com today. We're committed to providing exceptional
                customer service and exceeding your expectations.
              </p>
              <a
                href="mailto:sales@pickupxpress.com"
                className="bg-sky-400 text-white py-2 px-4 rounded-lg shadow-lg hover:bg-sky-700"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardSer;
