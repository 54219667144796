import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import agent from "../assets/agent.png";
import img1 from "../assets/img1.jpg";
import img2 from "../assets/img2.jpg";
import img3 from "../assets/img3.jpg";
import img4 from "../assets/img4.jpg";

import gd from "../assets/gd.gif";

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 1500,
  slidesToShow: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  slidesToScroll: 1,
  fade: false,
  arrows: false,
};

const reviews = [
  {
    name: "Swati",
    location: "Surat, India",
    rating: 5,
    text: "Amazing service! The booking process was seamless support cs is also very responsive.",
    img: img2,
  },
  {
    name: "Heera",
    location: "Delhi, India",
    rating: 5,
    text: "Good! The team is very supportive.",
    img: agent,
  },
  {
    name: "Ronit",
    location: "Mumbai, India",
    rating: 5,
    text: "I was impressed with how smooth the booking system was. Plus, the support team was always available and ready to help with any issues I encountered.",
    img: img4,
  },
  {
    name: "Sameer",
    location: "Noida, India",
    rating: 5,
    text: " Every time I had a question, the customer service team was quick to assist, making the process even easier.",
    img: img3,
  },
  {
    name: "Rahul",
    location: "Mumbai, India",
    rating: 4,
    text: "Great experience overall! The customer service team was very helpful.",
    img: img1,
  },
];

const quotes = [
  {
    quote: "Fastest In Limited Time Frame",
  },
  {
    quote: "Advance COD Settlement",
  },
  {
    quote: "Less RTO",
  },
];

const CardWithSlider = () => {
  return (
    <div className="-z-10 relative w-full mt-10 bg-sky-200 max-w-screen-xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden flex flex-col lg:flex-row border-white/80 bg-opacity-80 backdrop-blur-2xl backdrop-saturate-200">
      <div className="flex-none bg-sky-400 ring-offset-inherit shadow-2xl w-full h-fll border-solid rounded-e-full lg:w-1/2 justify-center items-center h-full">
        <img
          src={gd}
          alt="Card Image"
          className="w-fulll mt-24 h-96 w-full object-cover mr-auto"
        />
      </div>

      <div className="w-full lg:w-1/2 p-6">
        <Slider {...sliderSettings} className="h-10">
          {quotes.map((quote, index) => (
            <div
              key={index}
              className="flex flex-col items-center text-center p-4"
            >
              <p className="text-6xl text-sky-600 mb-4 font-serif md:font-serif">
                {quote.quote}
              </p>
            </div>
          ))}
        </Slider>
        <Slider {...sliderSettings} className="h-full mb-6 mt-48">
          {reviews.map((review, index) => (
            <div
              key={index}
              className="flex flex-col items-center text-center p-4"
            >
              <div className="flex items-center mb-4">
                <img
                  src={review.img}
                  alt={review.name}
                  className="w-16 h-16 rounded-full object-cover mr-4"
                />
                <div>
                  <h3 className="text-xl mr-10 font-semibold font-serif md:font-serif">
                    {review.name}
                  </h3>
                  <p className="text-gray-600 font-serif md:font-serif text-justify">
                    {review.location}
                  </p>
                  <div className="flex mt-1 font-serif md:font-serif">
                    {"★".repeat(review.rating)}
                    {"☆".repeat(5 - review.rating)}
                  </div>
                </div>
              </div>
              <p className="text-gray-800 ">"{review.text}"</p>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default CardWithSlider;
