import { Truck, Globe, Users, Weight } from "@styled-icons/fa-solid";
import { RiTeamFill } from "react-icons/ri";
import { PiMapPinArea } from "react-icons/pi";
import React, { useRef } from "react";

const stats = [
  {
    id: 1,
    title: "2M+",
    description: "Parcels shipped since inception",
    icon: <Truck className="w-12 h-12 text-white" />,
  },
  {
    id: 2,
    title: "95 %",
    description: "Indian population covered",
    icon: <Globe className="w-12 h-12 text-white" />,
  },
  {
    id: 3,
    title: "1K+",
    description: "Businesses served",
    icon: <Users className="w-12 h-12 text-white" />,
  },
  {
    id: 4,
    title: "20k+",
    description: "Tonnes Freight shipped",
    icon: <Weight className="w-12 h-12 text-white" />,
  },
  {
    id: 5,
    title: "50+ Team",
    description: "Servicing better then anyone",
    icon: <RiTeamFill className="w-12 h-12 text-white" />,
  },
  {
    id: 5,
    title: "25k +",
    description: "Maximum pin code covered",
    icon: <PiMapPinArea className="w-12 h-12 text-white" />,
  },
];

const Services = () => {
  const cardRef = useRef(null);

  const scrollToCard = () => {
    cardRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div
      className="w-full mt-10 max-w-screen-xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden flex flex-col lg:flex-row border-white/80 bg-opacity-80 backdrop-blur-2xl backdrop-saturate-200"
      id="services_provided"
      onClick={scrollToCard}
    >
      <div className="container mx-auto p-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {stats.map((stat) => (
          <div
            key={stat.id}
            className="bg-white shadow-lg rounded-lg p-6 flex flex-col items-center text-center transition transform hover:scale-105"
          >
            {/* Icon */}
            <div className="bg-sky-600 rounded-full p-4 mb-4">{stat.icon}</div>

            {/* Title */}
            <h3 className="text-2xl font-bold text-gray-800 mb-2">
              {stat.title}
            </h3>

            {/* Description */}
            <p className="text-gray-600">{stat.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
