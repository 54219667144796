import React from 'react';

const Smallpack = () => {
  return (
    <div className="block w-full mt-3 max-w-screen-xl px-4  mx-auto text-black bg-white border shadow-md 
  rounded-xl border-white/80 bg-opacity-80 backdrop-blur-2xl backdrop-saturate-200 lg:px-8 lg:py-4 py-12">
      {/* Header Section */}
      <header className="text-center my-6">
        <h1 className="text-4xl md:text-6xl font-bold text-sky-600">B2C Order Courier Services</h1>
        <p className="mt-4 text-lg md:text-xl text-gray-700">
          Fast, reliable, and secure deliveries tailored to your business needs.
        </p>
      </header>

      {/* Main Content Section */}
      <div className="max-w-7xl mx-auto">
        {/* Why Choose Section */}
        <section className="bg-white rounded-lg shadow-lg p-6 mb-8">
          <h2 className="text-3xl font-semibold text-sky-600 mb-4">Why Choose Our B2C Courier Services?</h2>
          <ul className="space-y-4 text-gray-700">
            <li>
              <strong>Fast and Reliable Deliveries:</strong> Nationwide network ensuring timely deliveries for your customers.
            </li>
            <li>
              <strong>Real-Time Tracking:</strong> Track parcels from dispatch to delivery for a transparent experience.
            </li>
            <li>
              <strong>Flexible Delivery Options:</strong> Choose from same-day, next-day, or scheduled deliveries.
            </li>
            <li>
              <strong>Secure and Safe Shipping:</strong> Robust packaging and handling to minimize damage risks.
            </li>
            <li>
              <strong>Seamless Integration:</strong> Integrate with popular platforms like Shopify, WooCommerce, Magento, and more.
            </li>
            <li>
              <strong>Customer-Centric Approach:</strong> Dedicated customer service to ensure a smooth delivery experience.
            </li>
          </ul>
        </section>

        {/* Industries We Serve Section */}
        <section className="bg-white rounded-lg shadow-lg p-6 mb-8">
          <h2 className="text-3xl font-semibold text-sky-600 mb-4">Industries We Serve</h2>
          <p className="text-gray-700">
            From e-commerce stores to retail chains, we offer tailored shipping solutions for various industries:
          </p>
          <ul className="mt-4 list-disc list-inside space-y-2 text-gray-700">
            <li>E-commerce</li>
            <li>Retail</li>
            <li>Subscription Boxes</li>
          </ul>
        </section>

        {/* How It Works Section */}
        <section className="bg-white rounded-lg shadow-lg p-6 mb-8">
          <h2 className="text-3xl font-semibold text-sky-600 mb-4">How It Works</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="text-center">
              <img
                src="https://via.placeholder.com/150"
                alt="Order Placement"
                className="mx-auto mb-4 w-24 h-24 object-cover"
              />
              <h3 className="text-xl font-semibold text-gray-800">Order Placement</h3>
              <p className="text-gray-700">
                Orders placed through your store are synced with our system.
              </p>
            </div>
            <div className="text-center">
              <img
                src="https://via.placeholder.com/150"
                alt="Pickup & Dispatch"
                className="mx-auto mb-4 w-24 h-24 object-cover"
              />
              <h3 className="text-xl font-semibold text-gray-800">Pickup & Dispatch</h3>
              <p className="text-gray-700">
                We pick up the parcel and dispatch it through our network.
              </p>
            </div>
            <div className="text-center">
              <img
                src="https://via.placeholder.com/150"
                alt="Delivery"
                className="mx-auto mb-4 w-24 h-24 object-cover"
              />
              <h3 className="text-xl font-semibold text-gray-800">Delivery</h3>
              <p className="text-gray-700">
                The parcel is delivered directly to your customer's doorstep.
              </p>
            </div>
          </div>
        </section>

        {/* Contact Us Section */}
        <section className="bg-sky-600 rounded-lg shadow-lg p-6 text-white">
          <h2 className="text-3xl font-semibold mb-4">Boost Your B2C Business with Reliable Courier Services</h2>
          <p className="text-lg mb-4">
            Focus on growing your business while we handle your logistics. Experience fast, efficient, and reliable delivery services tailored to your specific needs.
          </p>
          <a href="/contact-us" className="bg-white text-sky-600 px-4 py-2 rounded-lg font-semibold hover:bg-gray-200 transition duration-300">
            Contact Us Today
          </a>
        </section>
      </div>
    </div>
  );
};

export default Smallpack;
