import React, { useState, useRef, useEffect } from "react";
import truck from "../assets/truck.gif";
import location from "../assets/location.png";
import time from "../assets/time.png";
import loop from "../assets/loop.png";
import man from "../assets/man.png";
import money from "../assets/money.png";
import doortodoor from "../assets/doortodoor.png";
import { VscActivateBreakpoints } from "react-icons/vsc";

const benefits = [
  {
    title: "1.	Request a Quote ",
    content:
      "Start by requesting a quote through our website or customer service. Provide details about your shipment, including dimensions, weight, and destination.",
  },
  {
    title: "2.	Booking",
    content:
      "Once you receive a quote, you can book the shipment online or through our customer support team. Choose the pickup and delivery options that best suit your needs.",
  },
  {
    title: "3.	Pickup",
    content:
      "Our team will arrange for the pickup of your goods at the specified location. Ensure that your shipment is properly packaged and labeled for safe transport.",
  },
  {
    title: "4.	Consolidation",
    content:
      "Your shipment will be consolidated with other LTL shipments, maximizing truck space and reducing transportation costs.",
  },
  {
    title: "5.	Transport",
    content:
      "Your goods will be transported via our surface shipping network. Throughout the journey, you can track the status of your shipment in real-time.",
  },
  {
    title: "6.	Delivery",
    content:
      "Once the shipment reaches its destination terminal, it will be delivered to the specified delivery location. Choose from various delivery options, including curbside, door-to-door, or terminal pickup.",
  },
];

const Surfaceship = () => {
  const [openIndices, setOpenIndices] = useState([]);
  const accordionRef = useRef(null);

  const toggleAccordion = (index) => {
    setOpenIndices((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  const closeAllAccordions = () => {
    setOpenIndices([]);
  };

  const handleClickOutside = (event) => {
    if (accordionRef.current && !accordionRef.current.contains(event.target)) {
      closeAllAccordions();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="bg-white py-12">
        <div className="container mx-auto px-6">
          <h2 className=" text-4xl font-bold text-center mb-14 text-gray-700">
            Less Than Truckload (LTL){" "}
          </h2>

          <div className=" max-w-7xl mx-auto">
            <div className="flex flex-col lg:flex-row items-center">
              <div className=" card bg-white w-full lg:w-1/2 lg:h-96 flex justify-center text-center mb-6 lg:mb-0 rounded-lg shadow-lg">
                <img
                  src={truck}
                  alt="Express Parcels"
                  className="max-w-full max-h-full p-2 "
                />
              </div>

              <div className="w-full lg:w-1/2 lg:pl-12">
                <p className="text-gray-700 mb-6 text-lg">
                  <strong> PICKUPXPRESS</strong> offers efficient and
                  cost-effective <strong> Less Than Truckload (LTL) </strong>
                  surface shipping solutions for businesses and individuals. Our
                  LTL services are designed to cater to shipments that do not
                  require a full truckload, providing a flexible and economical
                  way to transport goods across various locations.
                </p>
              </div>
            </div>
            <section className="bg-white rounded-lg shadow-lg p-6 mb-8 mt-10 ">
              <div className="mt-8 ">
                <h1 className="text-3xl font-bold text-center mb-8 text-gray-700">
                  What is LTL Surface Shipping?{" "}
                </h1>
                <p className="text-gray-700 mt-4 mb-8 text-lg font-semibold px-8">
                  <strong> Less Than Truckload (LTL)</strong> surface shipping
                  is an ideal solution for transporting smaller shipments that
                  do not require the use of an entire truck. With LTL shipping,
                  multiple shipments from different customers are consolidated
                  into a single truck, allowing you to pay only for the space
                  your shipment occupies. This method optimizes shipping costs
                  while maintaining reliable delivery schedules.
                </p>
              </div>
              <div className=" py-12 bg-white-100 ">
                <div className="container mx-auto px-4">
                  <h1 className="text-3xl font-bold text-center mb-8 text-gray-700">
                    {" "}
                    Why Choose PICKUPXPRESS LTL Surface Shipping?{" "}
                  </h1>
                </div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 text-center">
                <div className="relative group">
                  <div className="card bg-white rounded-lg shadow-lg ">
                    <div className=" text-white p-4 inline-block">
                      <img src={money} alt="Fast delivery" className="w-16" />{" "}
                      <p className="text-lg font-semibold mt-2 text-sky-600">
                        Cost-Effective{" "}
                      </p>
                    </div>
                  </div>
                  <span className="z-50 font-semibold hidden group-hover:block absolute top-28 left-0 right-0 text-gray-800 bg-gray-100 shadow-lg p-4 rounded-lg">
                    Pay only for the space your shipment needs, making LTL
                    shipping a budget-friendly option for smaller freight loads.
                  </span>
                </div>
                <div className="relative group">
                  <div className="card bg-white rounded-lg shadow-lg ">
                    <div className=" text-white p-4 inline-block">
                      <img src={loop} alt="Fast delivery" className="w-16 " />{" "}
                      <p className="text-lg font-semibold mt-2 text-sky-600">
                        Nationwide Coverage{" "}
                      </p>
                    </div>
                  </div>
                  <span className="z-50  font-semibold hidden group-hover:block absolute top-28 left-0 right-0 text-gray-800 bg-gray-100 shadow-lg p-4 rounded-lg">
                    Our extensive network ensures that we can handle LTL
                    shipments to and from various locations across the country.
                  </span>
                </div>
                <div className="relative group">
                  <div className="card bg-white rounded-lg shadow-lg ">
                    <div className=" text-white p-4 inline-block">
                      <img src={time} alt="Fast delivery" className="w-16 " />{" "}
                      <p className="text-lg font-semibold mt-2 text-sky-600">
                        Scheduled Deliveries{" "}
                      </p>
                    </div>
                  </div>
                  <span className="z-50  font-semibold hidden group-hover:block absolute top-28 left-0 right-0 text-gray-800 bg-gray-100 shadow-lg p-4 rounded-lg">
                    With LTL shipping, your goods are transported on a scheduled
                    basis, ensuring timely and predictable deliveries.
                  </span>
                </div>
                <div className="relative group">
                  <div className="card bg-white rounded-lg shadow-lg ">
                    <div className=" text-white p-4 inline-block">
                      <img
                        src={location}
                        alt="location delivery"
                        className="w-16 "
                      />{" "}
                      <p className="text-lg font-semibold mt-2 text-sky-600">
                        Real-Time Tracking{" "}
                      </p>
                    </div>
                  </div>
                  <span className="z-50  font-semibold hidden group-hover:block absolute top-28 left-0 right-0 text-gray-800 bg-gray-100 shadow-lg p-4 rounded-lg">
                    Stay informed about your shipment's status with our
                    real-time tracking system, giving you peace of mind
                    throughout the shipping process.
                  </span>
                </div>
                <div className="relative group">
                  <div className="card bg-white rounded-lg shadow-lg ">
                    <div className=" text-white p-4 inline-block">
                      <img src={man} alt="Fast delivery" className="w-16 " />{" "}
                      <p className="text-lg font-semibold mt-2 text-sky-600">
                        Professional Handling{" "}
                      </p>
                    </div>
                  </div>
                  <span className="z-50  font-semibold hidden group-hover:block absolute top-28 left-0 right-0 text-gray-800 bg-gray-100 shadow-lg p-4 rounded-lg">
                    Our experienced team ensures that your goods are handled
                    with care, minimizing the risk of damage during transit.
                  </span>
                </div>
                <div className="relative group">
                  <div className="card bg-white rounded-lg shadow-lg ">
                    <div className=" text-white p-4 inline-block">
                      <img
                        src={doortodoor}
                        alt="Fast delivery"
                        className="w-16 "
                      />{" "}
                      <p className="text-lg font-semibold mt-2 text-sky-600">
                        Flexible Options{" "}
                      </p>
                    </div>
                  </div>
                  <span className="z-50  font-semibold hidden group-hover:block absolute top-28 left-0 right-0 text-gray-800 bg-gray-100 shadow-lg p-4 rounded-lg">
                    We offer flexible pickup and delivery options to suit your
                    specific needs, including door-to-door,
                    terminal-to-terminal, and more.
                  </span>
                </div>
              </div>
            </section>
            <section className="bg-white rounded-lg shadow-lg p-6 mb-8 mt-10 ">
              <h2 className="text-3xl font-semibold text-center text-gray-700 mb-8">
                LTL Surface Shipping Services{" "}
              </h2>
              <ul className=" grid grid-cols-1 lg:grid-cols-2 gap-4">
                <li>
                  <div className="text-sm  cursor-pointer group perspective p-4 h-44 w-full">
                    <div className="relative duration-1000 preserve-3d group-hover:my-rotate-y-180 h-full bg-gray-100 rounded-lg shadow-lg">
                      <div className="text-center  absolute backface-hidden h-full w-full flex items-center justify-center">
                        <strong className="text-sky-600 text-lg">
                          Standard LTL Shipping{" "}
                        </strong>
                      </div>
                      <div className="absolute my-rotate-y-180 backface-hidden h-full w-full bg-sky-600 shadow-lg rounded-md text-white flex items-center justify-center p-4 overflow-hidden">
                        Ideal for regular shipments that require a
                        cost-effective solution. Standard LTL provides reliable
                        delivery within a defined time frame.
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="text-sm  cursor-pointer group perspective p-4 h-44 w-full">
                    <div className="relative duration-1000 preserve-3d group-hover:my-rotate-y-180 h-full bg-gray-100 rounded-lg shadow-lg">
                      <div className="text-center  absolute backface-hidden h-full w-full flex items-center justify-center">
                        <strong className="text-sky-600 text-lg">
                          Express LTL Shipping{" "}
                        </strong>
                      </div>
                      <div className="absolute my-rotate-y-180 backface-hidden h-full w-full bg-sky-600 shadow-lg rounded-md text-white flex items-center justify-center p-4 overflow-hidden">
                        For shipments that need to reach their destination
                        faster, our Express LTL service offers expedited
                        delivery while still providing cost savings compared to
                        full truckload options.
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="text-sm  cursor-pointer group perspective p-4 h-44 w-full">
                    <div className="relative duration-1000 preserve-3d group-hover:my-rotate-y-180 h-full bg-gray-100 rounded-lg shadow-lg">
                      <div className="text-center  absolute backface-hidden h-full w-full flex items-center justify-center">
                        <strong className="text-sky-600 text-lg">
                          Freight Consolidation{" "}
                        </strong>
                      </div>
                      <div className="absolute my-rotate-y-180 backface-hidden h-full w-full bg-sky-600 shadow-lg rounded-md text-white flex items-center justify-center p-4 overflow-hidden">
                        We consolidate smaller shipments into one truck to
                        maximize efficiency and reduce costs, ensuring your
                        goods reach their destination safely and efficiently.
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="text-sm  cursor-pointer group perspective p-4 h-44 w-full">
                    <div className="relative duration-1000 preserve-3d group-hover:my-rotate-y-180 h-full bg-gray-100 rounded-lg shadow-lg">
                      <div className="text-center  absolute backface-hidden h-full w-full flex items-center justify-center">
                        <strong className="text-sky-600 text-lg">
                          Special Handling{" "}
                        </strong>
                      </div>
                      <div className="absolute my-rotate-y-180 backface-hidden h-full w-full bg-sky-600 shadow-lg rounded-md text-white flex items-center justify-center p-4 overflow-hidden">
                        For goods that require special handling, such as
                        fragile, oversized, or high-value items, we offer
                        tailored LTL services with additional care and security
                        measures.{" "}
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </section>
            <section className="bg-white rounded-lg shadow-lg p-8 mb-8 mt-10">
              <h2 className="text-3xl font-semibold text-center text-gray-800 mb-12">
                How LTL Surface Shipping Works with PICKUPXPRESS
              </h2>
              <ul
                className="grid grid-cols-1 lg:grid-cols-3 gap-8"
                ref={accordionRef}
              >
                {benefits.map((benefit, index) => (
                  <li key={index} className="group">
                    <div
                      className="cursor-pointer"
                      onClick={() => toggleAccordion(index)}
                    >
                      <div
                        className={`text-lg font-medium card bg-white rounded-lg shadow-lg p-6 text-center flex flex-col items-center transition-all duration-300 ${
                          openIndices.includes(index)
                            ? "bg-sky-600"
                            : "hover:bg-sky-500"
                        }`}
                      >
                        <span className="text-sky-600 text-4xl mb-4">
                          {openIndices.includes(index) ? "✔️" : "❓"}
                        </span>
                        <strong className="text-gray-800">
                          {benefit.title}
                        </strong>
                      </div>
                    </div>
                    {openIndices.includes(index) && (
                      <div className="text-sm bg-gray-200 rounded-lg shadow-lg p-4 mt-3 transition-all duration-500 opacity-100 font-semibold">
                        {benefit.content}
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </section>

            <div className="mt-8 mb-8">
              <div className="bg-gray-100 shadow-lg rounded-lg p-6 card  max-w-full mt-6">
                <h3 className="text-2xl font-semibold mt-3 mb-6 text-gray-700 text-center">
                  Benefits of PICKUPXPRESS LTL Surface Shipping{" "}
                </h3>

                <ul className="space-y-4 text-gray-700">
                  <li className="flex items-start space-x-3">
                    <VscActivateBreakpoints className="text-cyan-600 w-5 h-5" />
                    <span>
                      <strong>Reduced Shipping Costs:</strong> By sharing truck
                      space with other shipments, you only pay for the space you
                      use, leading to significant cost savings.
                    </span>
                  </li>
                  <li className="flex items-start space-x-3">
                    <VscActivateBreakpoints className="text-cyan-600 w-5 h-5" />
                    <span>
                      <strong> Environmentally Friendly:</strong> Consolidating
                      shipments reduces the number of trucks on the road,
                      lowering carbon emissions and contributing to a more
                      sustainable supply chain.
                    </span>
                  </li>
                  <li className="flex items-start space-x-3">
                    <VscActivateBreakpoints className="text-cyan-600 w-5 h-5" />
                    <span>
                      <strong> Scalable Solutions:</strong> LTL shipping is
                      perfect for businesses of all sizes, from small startups
                      to large enterprises, providing a scalable and flexible
                      shipping solution.
                    </span>
                  </li>
                  <li className="flex items-start space-x-3">
                    <VscActivateBreakpoints className="text-cyan-600 w-5 h-5" />
                    <span>
                      <strong> Customer Support:</strong> Our dedicated customer
                      support team is available to assist you throughout the
                      shipping process, from booking to delivery.
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <section className="bg-sky-600 rounded-lg shadow-lg p-6 text-white">
              <h2 className="text-3xl font-semibold mb-4">
                Contact PickupXpress Today
              </h2>
              <p className="text-lg mb-4">
                Ready to ship with{" "}
                <strong>PICKUPXPRESS LTL Surface Shipping ?</strong> Contact us
                today for a personalized quote or to learn more about how our
                LTL services can meet your shipping needs. Our team is here to
                help you find the best shipping solution for your business.
              </p>

              <a
                href="/Contactus"
                className="bg-white text-sky-600 px-4 py-2 rounded-lg font-semibold hover:bg-gray-200 transition duration-300"
              >
                Contact Us Today
              </a>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};
export default Surfaceship;
