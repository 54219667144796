import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Cancellation = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [isChecked, setIsChecked] = useState(false); // State for checkbox
  const navigate = useNavigate();

  const handleClose = () => {
    setIsOpen(false);
    navigate("/");
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); // Toggle the checked state
  };

  return (
    <>
      {isOpen && (
        <div className="max-h-screen fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-75">
          <div className="relative bg-white w-11/12 md:w-8/12 lg:w-6/12 xl:w-4/12 rounded-lg p-8 max-h-screen overflow-y-auto">
            <button
              onClick={handleClose}
              className="absolute top-4 right-4 text-gray-700 hover:text-gray-900"
            >
              &times;
            </button>
            <h2 className="text-3xl font-semibold mb-4 text-center text-sky-600">
              Cancellation Policy
            </h2>
            <p className="mb-4">
              We aim to offer dependable and efficient pickup and delivery services. We know that plans can change, so our cancellation policy is designed to keep things simple and fair for everyone.
            </p>
            <h3 className="font-bold mb-2">1. Cancellation Timeframes</h3>
            <ul className="list-disc ml-5 mb-4">
              <li>
                <strong>Same-Day Orders:</strong> Please cancel at least 2 hours before the scheduled pickup time to avoid a cancellation fee.
              </li>
              <li>
                <strong>Scheduled Orders:</strong> If you've scheduled a pickup in advance, please cancel at least 24 hours before the scheduled time.
              </li>
            </ul>
            <h3 className="font-bold mb-2">2. Cancellation Fees</h3>
            <ul className="list-disc ml-5 mb-4">
              <li>
                <strong>Same-Day Orders:</strong> Cancelling less than 2 hours before the pickup.
              </li>
              <li>
                <strong>Scheduled Orders:</strong> Cancelling less than 24 hours before the pickup time.
              </li>
            </ul>
            <h3 className="font-bold mb-2">3. No-Show Policy</h3>
            <p className="mb-4">
              If our driver arrives at the pickup location and the package is not ready or no one is available, a no-show fee of 10% of total freight will be charged. The driver will wait for up to 15 minutes.
            </p>
            <h3 className="font-bold mb-2">4. Refunds</h3>
            <ul className="list-disc ml-5 mb-4">
              <li>
                <strong>Eligible Cancellations:</strong> Cancellations made within the allowed timeframes will receive a full refund.
              </li>
              <li>
                <strong>Non-Eligible Cancellations:</strong> For cancellations made outside the allowed timeframes, any fees will be deducted from the refund.
              </li>
            </ul>
            <h3 className="font-bold mb-2">5. How to Cancel</h3>
            <ul className="list-disc ml-5 mb-4">
              <li>
                <strong>App:</strong> Use the "Cancel" option in the order details section of the PICKUPXPRESS app.
              </li>
              <li>
                <strong>Customer Support:</strong> Contact us at [customer support phone number or email] with your order details.
              </li>
            </ul>
            <h3 className="font-bold mb-2">6. Policy Changes</h3>
            <p className="mb-4">
              We may update this cancellation policy from time to time. Any changes will be communicated through our app and website, and they will be effective immediately.
            </p>
            <h3 className="font-bold mb-2">7. Contact Us</h3>
            <p className="mb-4">
              If you have any questions about this policy, please reach out to us at support@pickupxpress.com
            </p>
            <p className="text-base mt-4 flex items-center">
              <input
                id="cancellation-checkbox"
                type="checkbox"
                checked={isChecked} // Bind checkbox to state
                onChange={handleCheckboxChange} // Handle change event
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label htmlFor="cancellation-checkbox" className="ml-2">
                By using PICKUPXPRESS services, you acknowledge that you have read
                and understood this Cancellation Policy and agree to the terms described.
              </label>
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default Cancellation;
