import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from 'react-spring';
import 'slick-carousel/slick/slick-theme.css';
import Delhivery from '../assets/Delhivery.png';
import ecom from '../assets/ecom.svg';
import bd from '../assets/bd.jpeg';
import xb from '../assets/xb.png';
import Gati from '../assets/Gati.jpg';
import spice from '../assets/spice.svg';
import sfx from '../assets/sfx.png';
import ama from '../assets/ama.svg';
import movin from '../assets/movin.svg';
import dw from '../assets/dw.svg';

// Partners data with logos
const partners = [
  { name: 'Delhivery' , logo: Delhivery },
  { name: 'Ecom', logo: ecom },
  { name: 'Bluedart', logo: bd },
  { name: 'Xpressbees', logo: xb },
  { name: 'Gati', logo: Gati }, 
  { name: 'Safexpress', logo: sfx },
  { name: 'ATS', logo: ama },
  { name: 'Movin', logo: movin },
  { name: 'Spicejet', logo: spice},
  { name: 'DP World', logo: dw },
];

// Slider settings
const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    fade: false,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

const CardPart = () => {
    const { ref: sliderRef, inView: sliderInView } = useInView({
        triggerOnce: true,
        threshold: 0.2, // Trigger when 20% of the slider is visible
      });
      const scroll3D = useSpring({
        transform: sliderInView ? 'rotateY(0deg)' : 'rotateY(90deg)',
        opacity: sliderInView ? 1 : 0,
        config: { tension: 200, friction: 50 },
      });
  return (
    <div className="w-full mt-10 max-w-screen-xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden 
    flex flex-col lg:flex-row border-white/80 bg-opacity-80 backdrop-blur-2xl backdrop-saturate-200 py-12">
    <div className="container mx-auto px-6">
      <h2 className="text-3xl font-bold text-center mb-8 text-sky-600">
        Our Partners
      </h2>
      <animated.div
        ref={sliderRef}
        style={scroll3D}
        className="transform transition-transform duration-700"
      >
        <Slider {...sliderSettings}>
          {partners.map((partner, index) => (
            <div key={index} className="p-4">
              <div className="bg-sky-700 p-6 rounded-lg shadow-lg flex flex-col items-center text-center">
                <img
                  src={partner.logo}
                  alt={partner.name}
                  className="w-26 h-24 mb-0  mt-0 object-contain " 
                />
              </div>
            </div>
          ))}
        </Slider>
      </animated.div>
    </div>
  </div>
);
};


export default CardPart;
