import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { z } from "zod";
import toast from "react-hot-toast";
import rate from "../assets/rate.gif";
import { CiDeliveryTruck } from "react-icons/ci";
import { RiWeightLine } from "react-icons/ri";
import { IoIosArrowDown } from "react-icons/io";

const rateCalculatorSchema = z.object({
  pickupPincode: z
    .string()
    .length(6, "Pincode must be exactly 6 digits")
    .regex(/^\d+$/, "Pincode must be numeric"),
  dropPincode: z
    .string()
    .length(6, "Pincode must be exactly 6 digits")
    .regex(/^\d+$/, "Pincode must be numeric"),
  length: z
    .string()
    .min(1, "Length is required")
    .regex(/^\d+$/, "Length must be positive"),
  width: z
    .string()
    .min(1, "Width is required")
    .regex(/^\d+$/, "Width must be positive"),
  height: z
    .string()
    .min(1, "Height is required")
    .regex(/^\d+$/, "Height must be positive"),
  weight: z
    .number()
    .min(0.1, "Weight is required")
    .positive("Weight must be a positive number"),

  shipmentType: z.string().optional(),

  declaredValue: z
    .string()
    .min(0, "Declared Value must be a non-negative number")
    .optional(),
});

const RateCalculator = () => {
  const [message, setMessage] = useState(" ");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [data, setData] = useState();
  const [prepaidValue, setPrepaidValue] = useState(" ");
  const [loader, setLoader] = useState(false);
  const rateenv = process.env.REACT_APP_API_KEY;

  const [apiDetails, setApiDetails] = useState(false);

  const handleDetails = () => {
    setApiDetails(!apiDetails);
    // handleDetailsClose();
  };

  const handlePrepaidChange = (e) => {
    setPrepaidValue(e.target.value);
  };

  console.log("data", data);

  const handleInputChange = (event) => {
    const { value, id } = event.target;
    if (
      [
        "pickupPincode",
        "dropPincode",
        "weight",
        "length",
        "width",
        "height",
        "cod",
      ].includes(id)
    ) {
      const filteredValue = value.replace(/[^0-9]/g, "");
      event.target.value = filteredValue;
    }
  };

  const handleViewClick = () => {
    setIsPopupOpen(true);
  };

  const handleClose = () => {
    setIsPopupOpen(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(rateCalculatorSchema),
  });

  const onSubmit = async (formData) => {
    debugger;
    setLoader(true);
    const requestBody = {
      from_pincode: formData.pickupPincode,
      to_pincode: formData.dropPincode,
      length: parseFloat(formData.length),
      width: parseFloat(formData.width),
      height: parseFloat(formData.height),
      weight: parseFloat(formData.weight),
      shipment_type: formData.shipmentType,
      declared_value: formData.declaredValue
        ? parseFloat(formData.declaredValue)
        : "",
      prepaid: formData.prepaid,
      cod_amount: formData.prepaid === "cod" ? parseFloat(formData.cod) : "",
    };

    try {
      const response = await axios.post(rateenv, requestBody, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data.status === "success") {
        setData(response.data);
        console.log("response", response.data);
        reset();
        handleViewClick();
        toast.success(response.data.message);
        setMessage(response.data.message);
      } else {
        toast.error(response.data.message);
        setMessage(response.data.message);
      }
    } catch (error) {
      console.error(error.response ? error.response.data : error.message);
      toast.error(error.response?.data?.message || "Something went wrong");
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <div>
        <div className="block w-full mt-3 max-w-screen-xl px-4 mx-auto text-black bg-white border rounded-lg shadow-md  border-white/80 bg-opacity-80  lg:px-8 lg:py-4 py-12">
          <div className="container p-4 overflow-y-auto grid grid-cols-1 lg:grid-cols-2 gap-10">
            <div className=" w-full text-gray-800 p-4 md:p-8 dark:text-gray-800 shadow-lg dark:bg-gray-100 rounded-lg">
              <button className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 text-sky-600 px-2 sm:px-4 py-2 sm:py-1 h-[40px] w-[60%] sm:w-full flex items-center justify-center">
                Rate Calculator
              </button>
              <form
                className="space-y-6 font-semibold"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 dark:text-gray-800">
                  <div>
                    <label
                      htmlFor="pickupPincode"
                      className="block text-sm mb-1"
                    >
                      Pickup Pincode <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      maxLength="6"
                      id="pickupPincode"
                      className={`w-full px-3 py-2 shadow-lg rounded-lg dark:shadow-custom-dark dark:text-gray-800 dark:bg-gray-300 ${
                        errors.pickupPincode ? "border-red-500" : ""
                      }`}
                      {...register("pickupPincode")}
                      onChange={handleInputChange}
                    />
                    {errors.pickupPincode && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.pickupPincode.message}
                      </p>
                    )}
                  </div>

                  <div>
                    <label htmlFor="dropPincode" className="block text-sm mb-1">
                      Drop Pincode <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      id="dropPincode"
                      maxLength="6"
                      className={`w-full px-3 py-2 shadow-lg rounded-lg dark:shadow-custom-dark dark:text-gray-800 dark:bg-gray-300 ${
                        errors.dropPincode ? "border-red-500" : ""
                      }`}
                      {...register("dropPincode")}
                      onChange={handleInputChange}
                    />
                    {errors.dropPincode && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.dropPincode.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 dark:text-gray-800">
                  <div>
                    <label className="block text-sm mb-1">
                      Dimensions (in Cms){" "}
                      <span className="text-red-600">*</span>
                    </label>
                    <div className="grid grid-cols-3 gap-2">
                      <input
                        type="text"
                        id="length"
                        className={`w-full px-3 py-2 shadow-lg rounded-lg dark:shadow-custom-dark dark:text-gray-800 dark:bg-gray-300 ${
                          errors.length ? "border-red-500" : ""
                        }`}
                        placeholder="L"
                        {...register("length")}
                        onChange={handleInputChange}
                      />
                      <input
                        type="text"
                        id="width"
                        className={`w-full px-3 py-2 shadow-lg rounded-lg dark:shadow-custom-dark dark:text-gray-800 dark:bg-gray-300 ${
                          errors.width ? "border-red-500" : ""
                        }`}
                        placeholder="W"
                        {...register("width")}
                        onChange={handleInputChange}
                      />
                      <input
                        type="text"
                        id="height"
                        className={`w-full px-3 py-2 shadow-lg rounded-lg dark:shadow-custom-dark dark:text-gray-800 dark:bg-gray-300 ${
                          errors.height ? "border-red-500" : ""
                        }`}
                        placeholder="H"
                        {...register("height")}
                        onChange={handleInputChange}
                      />
                    </div>
                    {errors.length && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.length.message}
                      </p>
                    )}
                  </div>
                  <div>
                    <label className="block text-sm mb-1">
                      Weight (in kgs) <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      id="weight"
                      step="0.01"
                      className={`w-full px-3 py-2 shadow-lg rounded-lg dark:shadow-custom-dark dark:text-gray-800 dark:bg-gray-300 ${
                        errors.weight ? "border-red-500" : ""
                      }`}
                      {...register("weight", {
                        valueAsNumber: true,
                      })}
                    />
                    {errors.weight && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.weight.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 dark:text-gray-800">
                  <div>
                    <label
                      htmlFor="shipmentType"
                      className="block text-sm mb-1"
                    >
                      Shipment Type{" "}
                      <span className="text-gray-600">(Optional)</span>
                    </label>
                    <select
                      id="shipmentType"
                      className="w-full px-3 py-2 shadow-lg rounded-lg dark:shadow-custom-dark dark:text-gray-800 dark:bg-gray-300"
                      {...register("shipmentType")}
                    >
                      <option value="parcel">Regular</option>
                      <option value="document">Express</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor="prepaid" className="block text-sm mb-1">
                      Prepaid/COD <span className="text-red-600">*</span>
                    </label>
                    <select
                      id="prepaid"
                      value={prepaidValue}
                      onChange={(e) => {
                        setPrepaidValue(e.target.value);
                        handlePrepaidChange(e);
                      }}
                      className={`w-full px-3 py-2 shadow-lg rounded-lg dark:shadow-custom-dark dark:text-gray-800 dark:bg-gray-300 ${
                        errors.prepaid ? "border-red-500" : ""
                      }`}
                      {...register("prepaid")}
                    >
                      <option value="prepaid">Prepaid</option>
                      <option value="cod">COD</option>
                    </select>
                    {errors.prepaid && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.prepaid.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 dark:text-gray-800">
                  <div>
                    <label
                      htmlFor="declaredValue"
                      className="block text-sm mb-1"
                    >
                      Declared Value{" "}
                      <span className="text-gray-600">(Optional)</span>
                    </label>
                    <input
                      type="text"
                      id="declaredValue"
                      className="w-full px-3 py-2 shadow-lg rounded-lg dark:shadow-custom-dark dark:text-gray-800 dark:bg-gray-300"
                      {...register("declaredValue")}
                      onChange={handleInputChange}
                    />
                    {errors.declaredValue && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.declaredValue.message}
                      </p>
                    )}
                  </div>

                  {prepaidValue === "cod" && (
                    <div>
                      <label htmlFor="cod" className="block text-sm mb-1">
                        COD Amount
                      </label>
                      <input
                        type="text"
                        id="cod"
                        className="w-full px-3 py-2 shadow-lg rounded-lg dark:shadow-custom-dark dark:text-gray-800 dark:bg-gray-300"
                        {...register("cod")}
                        onChange={handleInputChange}
                      />
                      {errors.cod && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.cod.message}
                        </p>
                      )}
                    </div>
                  )}
                </div>

                <div className="w-full flex justify-center pt-8">
                  <button
                    type="submit"
                    className="bg-sky-600 text-white px-4 py-2 rounded-lg hover:bg-sky-500 flex items-center justify-center "
                    disabled={loader}
                  >
                    {loader ? (
                      <img src={rate} alt="Loading..." className="h-6" />
                    ) : (
                      "Calculate Rate"
                    )}
                  </button>
                </div>
              </form>
            </div>
            {data && (
              <div className=" w-full px-3 md:p-8 dark:text-gray-800 shadow-lg dark:bg-gray-100 rounded-lg">
                <div className="text-sky-600 text-lg font-semibold py-2 px-3">
                  {data.message}
                </div>
                <div className="mt-3 overflow-x-auto bg-gray-300 shadow-lg rounded-lg p-2 card w-full">
                  <div className="flex flex-row w-full justify-between  px-4">
                    {/* Services and Prices */}
                    {Object.entries(data.data.partners_pricing).map(
                      ([partnerName, services]) => (
                        <div
                          key={partnerName}
                          className="flex flex-row font-semibold justify-between w-full"
                        >
                          {Object.entries(services).map(
                            ([serviceType, pricing]) => (
                              <div
                                key={`${partnerName}-${serviceType}`}
                                className="flex flex-col items-center"
                              >
                                <p className=" py-2">{serviceType}</p>
                                <div className="flex items-center">
                                  <span className="text-sm font-bold pr-1">
                                    ₹
                                  </span>
                                  <h1 className="py-2 font-bold text-3xl">
                                    {pricing.cod_total_price}
                                  </h1>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      )
                    )}

                    {/* Truck Icon */}
                    {/* <div className="flex-col text-6xl text-gray-800 flex items-center">
                      <CiDeliveryTruck />
                    </div> */}
                  </div>
                </div>

                {/* <div className="mt-3 overflow-x-auto bg-gray-300 shadow-lg rounded-lg p-2 card w-full">
                  <div className=" flex flex-row w-full ">
                    <div className="flex ">
                      <div className="flex flex-row font-semibold justify-between ">
                        {Object.entries(data.data.partners_pricing).map(
                          ([partnerName, services]) =>
                            Object.entries(services).map(
                              ([serviceType, pricing]) => (
                                <div
                                  key={`${partnerName}-${serviceType}`}
                                  className="flex flex-col "
                                >
                                  <p className="px-3 py-2">{serviceType}</p>
                                  <div className="flex items-center">
                                    <span className="text-sm font-bold px-4">
                                      ₹
                                    </span>
                                    <h1 className=" py-2 font-bold text-3xl">
                                      {pricing.cod_total_price}
                                    </h1>
                                  </div>
                                </div>
                              )
                            )
                        )}
                        <div className="flex-col text-6xl text-gray-800 ">
                          <CiDeliveryTruck />
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className=" flex justify-between px-3 py-4">
                  <div className="flex flex-row gap-1">
                    <p className="text-sm text-gray-500 font-semibold">
                      From Pincode : {data.data.from_pincode}
                    </p>
                  </div>
                  <div className="flex flex-row gap-1">
                    <p className="text-sm text-gray-500 font-semibold">
                      To Pincode : {data.data.to_pincode}
                    </p>
                  </div>
                </div>

                <div className=" flex justify-between px-3">
                  <div className="flex flex-row gap-1">
                    <div className="text-lg text-gray-500 ">
                      <RiWeightLine />
                    </div>
                    <p className="text-sm text-gray-500 font-semibold">
                      Total Weight: {data.data.weight} Kg
                    </p>
                  </div>
                  <div className="flex flex-row gap-1">
                    <div className="text-lg text-gray-500 ">
                      <RiWeightLine />
                    </div>
                    <p className="text-sm text-gray-500 font-semibold">
                      Additional Gram : {data.data.additional_gm} g
                    </p>
                  </div>
                </div>

                <div className="flex flex-row justify-between">
                  <div
                    className="flex flex-row gap-1 mt-6 px-3 cursor-pointer"
                    onClick={handleDetails}
                  >
                    <p className="text-sm text-gray-600 font-semibold">
                      More Details
                    </p>
                    <div className="text-lg text-gray-600 mt-1">
                      <IoIosArrowDown />
                    </div>
                  </div>
                </div>
                {apiDetails && (
                  <div className="px-3 flex flex-row gap-6  ">
                    <div className="mt-3 px-4 overflow-x-auto bg-gray-300 shadow-lg rounded-lg p-4 card w-full">
                      <p className="text-gray-500 font-semibold text-base px-4 text-center pb-2">
                        Surface
                      </p>
                      <div className="flex flex-row justify-between px-4 py-2">
                        <p className="text-base text-black font-semibold">
                          Unit Price :{" "}
                        </p>
                        <p className="text-sm text-black font-semibold">
                          {
                            data.data.partners_pricing.Delhivery.surface
                              .unit_price
                          }
                        </p>
                      </div>

                      <div className="flex flex-row justify-between px-4 py-2">
                        <p className="text-sm text-black font-semibold">
                          Additional price :{" "}
                        </p>
                        <p className="text-sm text-black font-semibold">
                          {
                            data.data.partners_pricing.Delhivery.surface
                              .additional_price
                          }
                        </p>
                      </div>
                      <div className="flex flex-row justify-between px-4 py-2">
                        <p className="text-sm text-black font-semibold">
                          Base Price :{" "}
                        </p>
                        <p className="text-sm text-black font-semibold">
                          {
                            data.data.partners_pricing.Delhivery.surface
                              .base_price
                          }{" "}
                        </p>
                      </div>
                      <div className="flex flex-row justify-between px-4 py-2">
                        <p className="text-sm text-red-600 font-semibold">
                          Total Price :{" "}
                        </p>
                        <p className="text-sm text-red-600 font-semibold">
                          {
                            data.data.partners_pricing.Delhivery.surface
                              .total_price
                          }
                        </p>
                      </div>
                      <div className="flex flex-row justify-between px-4 py-2">
                        <p className="text-sm text-black font-semibold">
                          Cod Extra :{" "}
                        </p>
                        <p className="text-sm text-black font-semibold">
                          {
                            data.data.partners_pricing.Delhivery.surface
                              .cod_extra
                          }{" "}
                        </p>
                      </div>
                      <div className="flex flex-row justify-between px-4 py-2">
                        <p className="text-sm text-green-700 font-semibold">
                          Cod Total Price :{" "}
                        </p>
                        <p className="text-sm text-green-700 font-semibold">
                          {
                            data.data.partners_pricing.Delhivery.surface
                              .cod_total_price
                          }
                        </p>
                      </div>
                    </div>
                    <div className="mt-3 px-4 overflow-x-auto bg-gray-300 shadow-lg rounded-lg p-4 card  w-full">
                      <p className="text-gray-500 font-semibold text-base px-4 text-center pb-2">
                        Express
                      </p>
                      <div className="flex flex-row justify-between px-4 py-2">
                        <p className="text-sm text-black font-semibold">
                          Unit Price :{" "}
                        </p>
                        <p className="text-sm text-black font-semibold">
                          {
                            data.data.partners_pricing.Delhivery.express
                              .unit_price
                          }
                        </p>
                      </div>

                      <div className="flex flex-row justify-between px-4 py-2">
                        <p className="text-sm text-black font-semibold">
                          Additional price :{" "}
                        </p>
                        <p className="text-sm text-black font-semibold">
                          {
                            data.data.partners_pricing.Delhivery.express
                              .additional_price
                          }
                        </p>
                      </div>
                      <div className="flex flex-row justify-between px-4 py-2">
                        <p className="text-sm text-black font-semibold">
                          Base Price :{" "}
                        </p>
                        <p className="text-sm text-black font-semibold">
                          {
                            data.data.partners_pricing.Delhivery.express
                              .base_price
                          }{" "}
                        </p>
                      </div>
                      <div className="flex flex-row justify-between px-4 py-2">
                        <p className="text-sm text-red-600 font-semibold">
                          Total Price :{" "}
                        </p>
                        <p className="text-sm text-red-600 font-semibold">
                          {
                            data.data.partners_pricing.Delhivery.express
                              .total_price
                          }
                        </p>
                      </div>
                      <div className="flex flex-row justify-between px-4 py-2">
                        <p className="text-sm text-black font-semibold">
                          Cod Extra :{" "}
                        </p>
                        <p className="text-sm text-black font-semibold">
                          {
                            data.data.partners_pricing.Delhivery.express
                              .cod_extra
                          }{" "}
                        </p>
                      </div>
                      <div className="flex flex-row justify-between px-4 py-2">
                        <p className="text-sm text-green-700 font-semibold">
                          Cod Total Price :{" "}
                        </p>
                        <p className="text-sm text-green-700 font-semibold">
                          {
                            data.data.partners_pricing.Delhivery.express
                              .cod_total_price
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RateCalculator;
