import React from "react";
import tdvs from "../assets/tdvs.gif";
import { FaShippingFast } from "react-icons/fa";

const Express = () => {
  return (
    <div className="bg-white py-12">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold text-center mb-8 text-gray-800">
          Express Parcels
        </h2>

        {/* Responsive Flexbox for Image and Text */}
        <div className="flex flex-col lg:flex-row items-center">
          {/* Left Section (Image/Icon) */}
          <div className="w-full lg:w-1/2 flex justify-center lg:justify-end mb-6 lg:mb-0">
            <img
              src={tdvs}
              alt="Express Parcels"
              className="max-w-full h-auto rounded-lg shadow-lg"
            />
            {/* Optionally, replace image with an icon */}
            {<FaShippingFast className="w-48 h-48 text-cyan-600" />}
          </div>

          {/* Right Section (Text Content) */}
          <div className="w-full lg:w-1/2 lg:pl-12">
            <h3 className="text-2xl font-semibold text-gray-800 mb-4">
              Fast & Reliable Express Parcel Delivery
            </h3>
            <p className="text-gray-700 mb-4">
              Our Express Parcel service ensures that your urgent shipments
              reach their destination quickly and securely. Whether you are
              sending documents, packages, or gifts, we guarantee fast transit
              times with real-time tracking.
            </p>
            <p className="text-gray-700 mb-4">
              With a vast network of distribution centers and logistics
              partners, we offer same-day and next-day delivery options across
              various cities. Our service is ideal for businesses and
              individuals looking for reliable, time-sensitive deliveries.
            </p>
            <p className="text-gray-700 mb-4">
              Take advantage of our express shipping solutions, designed to
              cater to your specific needs, with a focus on speed, security, and
              convenience. From small parcels to larger shipments, we handle it
              all with professionalism and care.
            </p>
            <button className="mt-4 bg-cyan-600 text-white py-2 px-6 rounded-md hover:bg-cyan-700 transition-colors duration-300">
              Learn More
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Express;
