import React from "react";
import ecomm from "../assets/ecomm.gif";
import fast from "../assets/fast.png";
import time from "../assets/time.png";
import loop from "../assets/loop.png";
import small from "../assets/small.png";
import money from "../assets/money.png";
import ship from "../assets/ship.png";


const Ecommerce = () => {
  return (
    <>
      <div className="bg-white py-12">
        <div className="container mx-auto px-6">
          <h2 className=" text-4xl font-bold text-center mb-14 text-gray-700">
            Ecommerce Shipping Solutions
          </h2>

          <div className=" max-w-7xl mx-auto">
            <div className="flex flex-col lg:flex-row items-center">
              <div className=" card bg-white w-full lg:w-1/2 lg:h-96 flex justify-center text-center mb-6 lg:mb-0 rounded-lg shadow-lg">
                <img
                  src={ecomm}
                  alt="Express Parcels"
                  className="max-w-full max-h-full p-2 "
                />
              </div>

              <div className="w-full lg:w-1/2 lg:pl-12">
                <p className="text-gray-700 mb-6 text-lg">
                  At <strong>PickupXpress</strong>, we understand that seamless
                  and reliable shipping is the backbone of your ecommerce
                  business. Our
                  <strong> Ecommerce Shipping Solutions</strong> are designed to
                  help you deliver an outstanding customer experience from order
                  placement to delivery, ensuring your packages reach their
                  destination quickly and safely.
                </p>
              </div>
            </div>
            <section className="bg-white rounded-lg shadow-lg p-6 mb-8 mt-10 ">
              <div className=" py-12 bg-white-100 ">
                <div className="container mx-auto px-4">
                  <h1 className="text-3xl font-bold text-center mb-8 text-gray-700">
                    {" "}
                    Why Choose PickupXpress for Ecommerce Shipping?
                  </h1>
                </div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 text-center">
                <div className="relative group">
                  <div className="card bg-white rounded-lg shadow-lg ">
                    <div className=" text-white p-4 inline-block">
                      <img src={fast} alt="Fast delivery" className="w-16" />{" "}
                      <p className="text-lg font-semibold mt-2 text-sky-600">
                        Fast & Efficient Deliveries
                      </p>
                    </div>
                  </div>
                  <span className="z-50 font-semibold hidden group-hover:block absolute top-28 left-0 right-0 text-gray-800 bg-gray-100 shadow-lg p-4 rounded-lg">
                    Timely deliveries for customer satisfaction with fast,
                    efficient, and accurate order fulfillment.
                  </span>
                </div>
                <div className="relative group">
                  <div className="card bg-white rounded-lg shadow-lg ">
                    <div className=" text-white p-4 inline-block">
                      <img src={loop} alt="Fast delivery" className="w-16 " />{" "}
                      <p className="text-lg font-semibold mt-2 text-sky-600">
                        Nationwide Coverage{" "}
                      </p>
                    </div>
                  </div>
                  <span className="z-50  font-semibold hidden group-hover:block absolute top-28 left-0 right-0 text-gray-800 bg-gray-100 shadow-lg p-4 rounded-lg">
                    Whether you need to ship within a city or across the
                    country, PickupXpress has you covered. Our extensive network
                    ensures your ecommerce orders can reach even the most remote
                    areas, offering your business a competitive advantage.
                  </span>
                </div>
                <div className="relative group">
                  <div className="card bg-white rounded-lg shadow-lg ">
                    <div className=" text-white p-4 inline-block">
                      <img src={ship} alt="Fast delivery" className="w-16 " />{" "}
                      <p className="text-lg font-semibold mt-2 text-sky-600">
                        Multiple Carrier Options{" "}
                      </p>
                    </div>
                  </div>
                  <span className="z-50  font-semibold hidden group-hover:block absolute top-28 left-0 right-0 text-gray-800 bg-gray-100 shadow-lg p-4 rounded-lg">
                    We partner with top logistics providers to offer you a
                    variety of shipping options. This ensures that you can
                    choose the best carrier for each package, balancing cost and
                    delivery speed.
                  </span>
                </div>
                <div className="relative group">
                  <div className="card bg-white rounded-lg shadow-lg ">
                    <div className=" text-white p-4 inline-block">
                      <img src={time} alt="Fast delivery" className="w-16 " />{" "}
                      <p className="text-lg font-semibold mt-2 text-sky-600">
                        Real-Time Tracking{" "}
                      </p>
                    </div>
                  </div>
                  <span className="z-50  font-semibold hidden group-hover:block absolute top-28 left-0 right-0 text-gray-800 bg-gray-100 shadow-lg p-4 rounded-lg">
                    PickupXpress provides end-to-end visibility, allowing your
                    customers to track their orders every step of the way.
                  </span>
                </div>
                <div className="relative group">
                  <div className="card bg-white rounded-lg shadow-lg ">
                    <div className=" text-white p-4 inline-block">
                      <img src={small} alt="Fast delivery" className="w-16 " />{" "}
                      <p className="text-lg font-semibold mt-2 text-sky-600">
                        Integrated Shipping Solutions{" "}
                      </p>
                    </div>
                  </div>
                  <span className="z-50  font-semibold hidden group-hover:block absolute top-28 left-0 right-0 text-gray-800 bg-gray-100 shadow-lg p-4 rounded-lg">
                    Our system integrates seamlessly with popular ecommerce
                    platforms such as Shopify, WooCommerce, Magento, and more.
                    Whether you run a small boutique store or manage a
                    large-scale operation, PickupXpress offers hassle-free
                    integration to streamline your shipping process.
                  </span>
                </div>
                <div className="relative group">
                  <div className="card bg-white rounded-lg shadow-lg ">
                    <div className=" text-white p-4 inline-block">
                      <img src={money} alt="Fast delivery" className="w-16 " />{" "}
                      <p className="text-lg font-semibold mt-2 text-sky-600">
                        Cost-Effective Shipping{" "}
                      </p>
                    </div>
                  </div>
                  <span className="z-50  font-semibold hidden group-hover:block absolute top-28 left-0 right-0 text-gray-800 bg-gray-100 shadow-lg p-4 rounded-lg">
                    PickupXpress offers competitive shipping rates, bulk
                    shipping discounts, and optimized delivery routes, ensuring
                    you save on every shipment without compromising on quality.
                  </span>
                </div>
              </div>
            </section>
            <section className="bg-white rounded-lg shadow-lg p-6 mb-8 mt-10 ">
              <div className="bg-white rounded-lg shadow-lg p-4 mb-8 mt-4">
                <h2 className="text-3xl font-bold text-center text-gray-700 mb-8">
                  Additional Benefits
                </h2>
                <ul className=" grid grid-cols-1 lg:grid-cols-3 gap-4">
                  <li>
                    <div className="text-sm  cursor-pointer group perspective p-4 h-44 w-full">
                      <div className="relative duration-1000 preserve-3d group-hover:my-rotate-y-180 h-full bg-gray-100 rounded-lg shadow-lg">
                        <div className="text-center  absolute backface-hidden h-full w-full flex items-center justify-center">
                          <strong className="text-sky-600 text-lg">
                            Flexible Return Management
                          </strong>
                        </div>
                        <div className="absolute my-rotate-y-180 backface-hidden h-full w-full bg-sky-600 shadow-lg rounded-md text-white flex items-center justify-center p-4 overflow-hidden">
                          Manage returns with ease using PickupXpress’s
                          comprehensive return solutions. We help you provide a
                          smooth and hassle-free returns experience for your
                          customers, boosting their confidence in your brand.
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="text-sm  cursor-pointer group perspective p-4 h-44 w-full">
                      <div className="relative duration-1000 preserve-3d group-hover:my-rotate-y-180 h-full bg-gray-100 rounded-lg shadow-lg">
                        <div className="text-center  absolute backface-hidden h-full w-full flex items-center justify-center">
                          <strong className="text-sky-600 text-lg">
                            Cash on Delivery (COD){" "}
                          </strong>
                        </div>
                        <div className="absolute my-rotate-y-180 backface-hidden h-full w-full bg-sky-600 shadow-lg rounded-md text-white flex items-center justify-center p-4 overflow-hidden">
                          PickupXpress supports COD services to enhance
                          flexibility for your customers. Whether your business
                          offers prepaid or cash-on-delivery options, we ensure
                          secure handling of payments and prompt delivery.
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="text-sm  cursor-pointer group perspective p-4 h-44 w-full">
                      <div className="relative duration-1000 preserve-3d group-hover:my-rotate-y-180 h-full bg-gray-100 rounded-lg shadow-lg">
                        <div className="text-center  absolute backface-hidden h-full w-full flex items-center justify-center">
                          <strong className="text-sky-600 text-lg">
                            Custom Packaging Options{" "}
                          </strong>
                        </div>
                        <div className="absolute my-rotate-y-180 backface-hidden h-full w-full bg-sky-600 shadow-lg rounded-md text-white flex items-center justify-center p-4 overflow-hidden">
                          Protect your products with tailored packaging
                          solutions designed for ecommerce shipping. We offer
                          custom packaging options to ensure your goods arrive
                          safely and in pristine condition.
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="mt-8 ">
                <h2 className="text-3xl font-semibold text-gray-700 mb-4 text-center">
                  Who we serve ?
                </h2>
                <p className="text-gray-700 mt-4 text-base font-semibold">
                  From fashion and electronics to home goods and personal care
                  products, PickupXpress is the trusted shipping partner for a
                  wide range of ecommerce businesses. Our scalable shipping
                  solutions are designed to grow with your business, ensuring
                  you always have the support you need to succeed.
                </p>
              </div>
            </section>

            <section className="bg-sky-600 rounded-lg shadow-lg p-6 text-white">
              <h2 className="text-3xl font-semibold mb-4">
                Contact PickupXpress Today
              </h2>
              <p className="text-lg mb-4">
                Ready to take your ecommerce shipping to the next level? Let
                <strong>&nbsp;PickupXpress</strong> be your partner in success.
                Contact us today to learn more about our ecommerce shipping
                solutions and get a customized quote for your business
              </p>

              <a
                href="/Contactus"
                className="bg-white text-sky-600 px-4 py-2 rounded-lg font-semibold hover:bg-gray-200 transition duration-300"
              >
                Contact Us Today
              </a>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};
export default Ecommerce;
