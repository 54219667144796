import React from "react";
import { RiCommunityFill } from "react-icons/ri";
import { PiPlantFill } from "react-icons/pi";
import { FaGraduationCap } from "react-icons/fa6";
import { FaHandHoldingHeart } from "react-icons/fa";
import { FaHandsHoldingCircle } from "react-icons/fa6";
import { IoBusiness } from "react-icons/io5";
import { FaCircleCheck } from "react-icons/fa6";
import { IoIosPeople } from "react-icons/io";

import { FaShippingFast, FaRegHandshake, FaMoneyBillAlt } from "react-icons/fa";
import { MdOutlineError } from "react-icons/md";
import { GiThreeLeaves } from "react-icons/gi";
import env from "../assets/env.jpg";
import Employees from "../assets/Employees.jpg";

const Csr = () => {
  return (
    <>
      <div
        className="-z-50 relative block w-full mt-3 max-w-screen-xl px-4  mx-auto text-black bg-white border shadow-md
  rounded-xl border-white/80 bg-opacity-80 backdrop-blur-2xl backdrop-saturate-200 lg:px-8 lg:py-4 py-12"
      >
        <h2 className="text-4xl font-bold text-center mb-16 text-sky-600">
          Corporate Social Responsibility (CSR)
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-8">
          <div className="bg-white shadow-lg rounded-lg p-6 flex flex-col items-center justify-center space-y-4 h-full">
            <RiCommunityFill className="text-sky-600 w-10 h-10" />
            <h3 className="text-xl font-semibold text-gray-800">
              Community Development
            </h3>
            <p className="text-gray-700">
              We support local initiatives, education, and skill development
              programs to improve the quality of life in the communities where
              we operate.
            </p>
          </div>

          {/* Card 2 */}
          <div className="bg-sky-600 shadow-lg rounded-lg p-6 flex flex-col items-center justify-center space-y-4 text-white h-full">
            <PiPlantFill className="w-10 h-10" />
            <h3 className="text-xl font-semibold">
              Environmental Sustainability
            </h3>
            <p>
              Our goal is to minimize our carbon footprint by adopting
              eco-friendly practices and sustainable packaging.
            </p>
          </div>

          {/* Card 3 */}
          <div className="bg-gray-100 shadow-lg rounded-lg p-6 flex flex-col items-center justify-center space-y-4 h-full">
            <FaHandHoldingHeart className="text-sky-600 w-10 h-10" />
            <h3 className="text-xl font-semibold text-gray-800">
              Employee Welfare
            </h3>
            <p className="text-gray-700">
              We focus on fair labor practices, health and safety measures, and
              continuous learning programs for our employees.
            </p>
          </div>

          {/* Card 4 */}
          <div className="bg-gray-100 shadow-lg rounded-lg p-6 flex flex-col items-center justify-center space-y-4 h-full">
            <FaMoneyBillAlt className="text-sky-600 w-10 h-10" />
            <h3 className="text-xl font-semibold text-gray-800">
              Ethical Business Practices
            </h3>
            <p className="text-gray-700">
              We adhere to ethical practices, transparency, and integrity in all
              our operations.
            </p>
          </div>

          {/* Card 5 */}
          <div className="bg-white shadow-lg rounded-lg p-6 flex flex-col items-center justify-center space-y-4 h-full">
            <FaGraduationCap className="text-sky-600 w-10 h-10" />
            <h3 className="text-xl font-semibold text-gray-800">
              Education & Skill Development
            </h3>
            <p className="text-gray-700">
              We partner with educational institutions and organizations to
              provide learning opportunities for underprivileged communities.
            </p>
          </div>

          {/* Card 6 */}
          <div className="bg-sky-600 shadow-lg rounded-lg p-6 flex flex-col items-center justify-center space-y-4 text-white h-full">
            <FaHandsHoldingCircle className="w-10 h-10" />
            <h3 className="text-xl font-semibold">Charitable Contributions</h3>
            <p>
              We support charitable organizations and encourage employees to
              engage in community service.
            </p>
          </div>
          <div className="bg-sky-600 shadow-lg rounded-lg p-6 flex flex-col items-center justify-center space-y-4 text-white h-full">
            <GiThreeLeaves className="w-10 h-10" />
            <h3 className="text-xl font-semibold">Environmental Initiatives</h3>
            <ul>
              <li className="mb-3">
                <strong>Eco-Friendly Operations:</strong> We aim to reduce our
                environmental impact by implementing eco-friendly practices,
                such as using fuel-efficient vehicles, optimizing delivery
                routes to reduce emissions, and promoting paperless operations.
              </li>
              <li className="mb-3">
                <strong> Waste Reduction:</strong> We are committed to reducing
                waste by encouraging recycling, reusing materials, and promoting
                the use of sustainable packaging solutions.
              </li>
              <li className="mb-3">
                <strong>Energy Conservation:</strong> We strive to reduce energy
                consumption by using energy-efficient equipment and adopting
                best practices to conserve resources across our operations.
              </li>
            </ul>
          </div>
          <div className="bg-white shadow-lg rounded-lg flex flex-col items-center justify-center h-full">
            <img
              src={env}
              alt="Tree Plantation"
              className="w-full h-full object-cover rounded-lg"
            />
          </div>
          <div className="bg-gray-100 shadow-lg rounded-lg p-6 flex flex-col items-center justify-center space-y-4 h-full">
            <IoIosPeople className="w-10 h-10 text-sky-600" />
            <h3 className="text-xl font-semibold text-gray-800">
              Employee Welfare and Diversity{" "}
            </h3>
            <ul>
              <li className="mb-3">
                <strong>Safe Work Environment: </strong> We prioritize the
                health and safety of our employees by maintaining safe working
                conditions, providing appropriate training, and adhering to
                occupational health and safety standards.
              </li>
              <li className="mb-3">
                <strong>Diversity and Inclusion: </strong> We promote a culture
                of diversity and inclusion by offering equal opportunities to
                all employees, regardless of gender, race, ethnicity, religion,
                or background.
              </li>
              <li className="mb-3">
                <strong>Employee Development: </strong> We invest in the growth
                and development of our employees by offering training programs
                and career development opportunities.
              </li>
            </ul>
          </div>
          <div className="bg-white shadow-lg rounded-lg flex flex-col items-center justify-center h-full">
            <img
              src={Employees}
              alt="Tree Plantation"
              className="w-full h-full object-cover rounded-lg"
            />
          </div>

          <div className="bg-sky-600 shadow-lg rounded-lg p-6 flex flex-col items-center text-white justify-center space-y-4 h-full">
            <IoBusiness className="w-10 h-10 " />
            <h3 className="text-xl font-semibold ">
              Ethical Business Conduct{" "}
            </h3>
            <ul>
              <li className="mb-3">
                <strong>Compliance and Integrity:</strong>
                We are committed to conducting our business with integrity and
                in compliance with all applicable laws and regulations. We
                maintain transparency in our operations and adhere to ethical
                standards in all our dealings.
              </li>
              <li className="mb-3">
                <strong>Anti-Corruption: </strong> PICKUPXPRESS has a
                zero-tolerance policy towards corruption and bribery. We expect
                our employees, partners, and suppliers to act ethically and
                comply with our anti-corruption guidelines.
              </li>
            </ul>
          </div>
          <div className="bg-white shadow-lg rounded-lg p-6 flex flex-col items-center justify-center space-y-4 h-full">
            <FaCircleCheck className="text-sky-600 w-10 h-10" />
            <h3 className="text-xl font-semibold text-gray-800">
              Monitoring and Reporting{" "}
            </h3>
            <p className="text-gray-700">
              We regularly monitor our CSR initiatives to assess their
              effectiveness and identify areas for improvement. We will report
              our CSR activities and progress periodically to maintain
              transparency with our stakeholders.
            </p>
            <h3 className="text-xl font-semibold text-gray-800">
              Review and Amendments{" "}
            </h3>
            <p className="text-gray-700">
              This CSR Policy is subject to periodic review and may be updated
              to reflect changes in our CSR strategy or in response to evolving
              social, environmental, and business conditions.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Csr;
