import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./Layout";
import Home from "./Pages/Home";
import Refundpolicy from "./Pages/Refundpolicy";
import Express from "./Pages/Express";
import Smallpack from "./Pages/Smallpack";
import Contactus from "./Pages/Contactus";
import Ecommerce from "./Pages/Ecommerce";
import Aboutus from "./Pages/Aboutus";
import ExportImp from "./Pages/ExportImp";
import Surfaceship from "./Pages/Surfaceship";
import Truckload from "./Pages/Truckload";
import Traincargo from "./Pages/Traincargo";
import Accessibility from "./Pages/Accessibility";
import Csr from "./Pages/Csr";
import Privacypolicy from "./Pages/Privacypolicy";
import Tandc from "./Pages/Tandc";
import Cancellation from "./Pages/Cancellation";
import Career from "./Pages/Career";
import RateCalculator from "./Pages/RateCalculator";
import Cookies from "./Pages/Cookies";
import ScrollToTopButton from "./Pages/ScrollToTopButton";
import Financial from "./Pages/Financial";
import Companyprofile from "./Pages/Companyprofile";

const App = () => {
  return (
    <div>
      <div>
        <Router>
          <ScrollToTopButton />
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="Refundpolicy" element={<Refundpolicy />} />
              <Route path="Express" element={<Express />} />
              <Route path="Smallpack" element={<Smallpack />} />
              <Route path="Contactus" element={<Contactus />} />
              <Route path="Aboutus" element={<Aboutus />} />
              <Route path="Ecommerce" element={<Ecommerce />} />
              <Route path="ExportImp" element={<ExportImp />} />
              <Route path="Surfaceship" element={<Surfaceship />} />
              <Route path="Truckload" element={<Truckload />} />
              <Route path="Traincargo" element={<Traincargo />} />
              <Route path="Accessibility" element={<Accessibility />} />
              <Route path="Csr" element={<Csr />} />
              <Route path="Privacypolicy" element={<Privacypolicy />} />
              <Route path="Tandc" element={<Tandc />} />
              <Route path="Cancellation" element={<Cancellation />} />
              <Route path="Career" element={<Career />} />
              <Route path="RateCalculator" element={<RateCalculator />} />
              <Route path="Cookies" element={<Cookies />} />
              <Route path="Financial" element={<Financial />} />
              <Route path="Companyprofile" element={<Companyprofile />} />
            </Route>
          </Routes>
        </Router>
      </div>
    </div>
  );
};

export default App;
