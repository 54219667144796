import React from "react";
import Warehouse_Worker from "../assets/Warehouse_Worker.gif";
import thumbsup from "../assets/thumbsup.png";
import energyeff from "../assets/energyeff.png";
import box from "../assets/box.png";
import globe from "../assets/globe.png";
import money from "../assets/money.png";
import boxes from "../assets/boxes.png";
import { VscActivateBreakpoints } from "react-icons/vsc";

const Traincargo = () => {
  return (
    <>
      <div className="bg-white py-12">
        <div className="container mx-auto px-6">
          <h2 className=" text-4xl font-bold text-center mb-4 text-gray-700">
            Train Cargo:
          </h2>
          <h2 className=" text-4xl font-semibold text-center mb-14 text-gray-700">
            An Efficient and Sustainable Freight Solution with PICKUPXPRESS{" "}
          </h2>

          <div className=" max-w-7xl mx-auto">
            <div className="flex flex-col lg:flex-row items-center">
              <div className=" card bg-white w-full lg:w-1/2 lg:h-96 flex justify-center text-center mb-6 lg:mb-0 rounded-lg shadow-lg">
                <img
                  src={Warehouse_Worker}
                  alt="Express Parcels"
                  className="max-w-full max-h-full p-2 "
                />
              </div>

              <div className="w-full lg:w-1/2 lg:pl-12">
                <p className="text-gray-700 mb-6 text-lg">
                  Train cargo is a key component of global freight
                  transportation, offering a cost-effective and environmentally
                  sustainable way to move large volumes of goods over long
                  distances. It is particularly suitable for bulk shipments such
                  as coal, minerals, agricultural products, and manufactured
                  goods. By utilizing dedicated rail networks, train cargo
                  provides reliable and timely transportation, making it an
                  essential part of modern supply chains.
                </p>
                <p className="text-gray-700 mb-6 text-lg">
                  <p className="text-gray-700 mb-6 text-lg">
                    PICKUPXPRESS is a logistics provider that offers
                    comprehensive train cargo services, helping businesses
                    optimize their shipping operations with rail transport.
                    Their services combine the benefits of train cargo with
                    customized solutions to meet the diverse needs of their
                    clients.
                  </p>
                </p>
              </div>
            </div>
            <section className="bg-white rounded-lg shadow-lg p-6 mb-8 mt-10 ">
              <div className=" py-12 bg-white-100 ">
                <div className="container mx-auto px-4">
                  <h1 className="text-3xl font-bold text-center mb-8 text-gray-700">
                    {" "}
                    Key Benefits of Train Cargo{" "}
                  </h1>
                </div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 text-center">
                <div className="relative group">
                  <div className="card bg-white rounded-lg shadow-lg ">
                    <div className=" text-white p-4 inline-block">
                      <img src={box} alt="Fast delivery" className="w-16" />{" "}
                      <p className="text-lg font-semibold mt-2 text-sky-600">
                        High Capacity{" "}
                      </p>
                    </div>
                  </div>
                  <span className="z-50 font-semibold hidden group-hover:block absolute top-28 left-0 right-0 text-gray-800 bg-gray-100 shadow-lg p-4 rounded-lg">
                    Trains can carry large volumes of goods, making them ideal
                    for transporting bulk items. This high capacity enables
                    businesses to move significant quantities efficiently,
                    reducing the need for multiple trips.
                  </span>
                </div>
                <div className="relative group">
                  <div className="card bg-white rounded-lg shadow-lg ">
                    <div className=" text-white p-4 inline-block">
                      <img src={money} alt="Fast delivery" className="w-16 " />{" "}
                      <p className="text-lg font-semibold mt-2 text-sky-600">
                        Cost-Effective{" "}
                      </p>
                    </div>
                  </div>
                  <span className="z-50  font-semibold hidden group-hover:block absolute top-28 left-0 right-0 text-gray-800 bg-gray-100 shadow-lg p-4 rounded-lg">
                    Train cargo is often more economical for long-distance and
                    high-volume shipments. By moving large loads at once,
                    businesses can reduce the cost per unit of freight, making
                    rail transport a budget-friendly option.
                  </span>
                </div>
                <div className="relative group">
                  <div className="card bg-white rounded-lg shadow-lg ">
                    <div className=" text-white p-4 inline-block">
                      <img
                        src={energyeff}
                        alt="Fast delivery"
                        className="w-16 "
                      />{" "}
                      <p className="text-lg font-semibold mt-2 text-sky-600">
                        Energy Efficiency{" "}
                      </p>
                    </div>
                  </div>
                  <span className="z-50  font-semibold hidden group-hover:block absolute top-28 left-0 right-0 text-gray-800 bg-gray-100 shadow-lg p-4 rounded-lg">
                    Trains are more fuel-efficient than trucks, resulting in
                    lower greenhouse gas emissions per ton-mile. This makes
                    train cargo a more sustainable transport option, aligning
                    with efforts to reduce the environmental impact of
                    logistics.
                  </span>
                </div>
                <div className="relative group">
                  <div className="card bg-white rounded-lg shadow-lg ">
                    <div className=" text-white p-4 inline-block">
                      <img
                        src={thumbsup}
                        alt="location delivery"
                        className="w-16 "
                      />{" "}
                      <p className="text-lg font-semibold mt-2 text-sky-600">
                        Reliability{" "}
                      </p>
                    </div>
                  </div>
                  <span className="z-50  font-semibold hidden group-hover:block absolute top-28 left-0 right-0 text-gray-800 bg-gray-100 shadow-lg p-4 rounded-lg">
                    With dedicated tracks and fixed schedules, trains are less
                    susceptible to delays caused by road traffic or weather
                    conditions. This reliability ensures consistent delivery
                    times, helping businesses maintain smooth operations.
                  </span>
                </div>
                <div className="relative group">
                  <div className="card bg-white rounded-lg shadow-lg ">
                    <div className=" text-white p-4 inline-block">
                      <img src={globe} alt="Fast delivery" className="w-16 " />{" "}
                      <p className="text-lg font-semibold mt-2 text-sky-600">
                        Long-Distance Transport{" "}
                      </p>
                    </div>
                  </div>
                  <span className="z-50  font-semibold hidden group-hover:block absolute top-28 left-0 right-0 text-gray-800 bg-gray-100 shadow-lg p-4 rounded-lg">
                    Trains are well-suited for cross-country and international
                    shipments, capable of covering long distances without the
                    need for frequent transfers. This makes them a preferred
                    option for transporting goods across continents.
                  </span>
                </div>
                <div className="relative group">
                  <div className="card bg-white rounded-lg shadow-lg ">
                    <div className=" text-white p-4 inline-block">
                      <img src={boxes} alt="Fast delivery" className="w-16 " />{" "}
                      <p className="text-lg font-semibold mt-2 text-sky-600">
                        Versatility{" "}
                      </p>
                    </div>
                  </div>
                  <span className="z-50  font-semibold hidden group-hover:block absolute top-28 left-0 right-0 text-gray-800 bg-gray-100 shadow-lg p-4 rounded-lg">
                    Train cargo can handle a wide range of goods, from raw
                    materials to containerized freight. This versatility allows
                    businesses in various industries to leverage rail transport
                    for their shipping needs.
                  </span>
                </div>
              </div>
            </section>
            <section className="bg-white rounded-lg shadow-lg p-6 mb-8 mt-10 ">
              <h2 className="text-3xl font-semibold text-center text-gray-700 mb-8">
                PickupXpress and Train Cargo Services
              </h2>
              <ul className=" grid grid-cols-1 lg:grid-cols-3 gap-4">
                <li>
                  <div className="text-sm  cursor-pointer group perspective p-4 h-44 w-full">
                    <div className="relative duration-1000 preserve-3d group-hover:my-rotate-y-180 h-full bg-gray-100 rounded-lg shadow-lg">
                      <div className="text-center  absolute backface-hidden h-full w-full flex items-center justify-center">
                        <strong className="text-sky-600 text-lg">
                          Integrated Rail Solutions{" "}
                        </strong>
                      </div>
                      <div className="absolute my-rotate-y-180 backface-hidden h-full w-full bg-sky-600 shadow-lg rounded-md text-white flex items-center justify-center p-4 overflow-hidden">
                        PICKUPXPRESS provides end-to-end train cargo services,
                        from coordinating the pickup of goods to arranging rail
                        transport and final delivery. Their integrated approach
                        ensures a seamless shipping process, allowing businesses
                        to focus on their core operations.
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="text-sm  cursor-pointer group perspective p-4 h-44 w-full">
                    <div className="relative duration-1000 preserve-3d group-hover:my-rotate-y-180 h-full bg-gray-100 rounded-lg shadow-lg">
                      <div className="text-center  absolute backface-hidden h-full w-full flex items-center justify-center">
                        <strong className="text-sky-600 text-lg">
                          Customized Shipping Plans{" "}
                        </strong>
                      </div>
                      <div className="absolute my-rotate-y-180 backface-hidden h-full w-full bg-sky-600 shadow-lg rounded-md text-white flex items-center justify-center p-4 overflow-hidden">
                        Understanding that each shipment is unique, PICKUPXPRESS
                        works with clients to develop customized train cargo
                        solutions. They consider factors such as cargo type,
                        volume, and delivery timeline to select the most
                        suitable rail service for each shipment.
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="text-sm  cursor-pointer group perspective p-4 h-44 w-full">
                    <div className="relative duration-1000 preserve-3d group-hover:my-rotate-y-180 h-full bg-gray-100 rounded-lg shadow-lg">
                      <div className="text-center  absolute backface-hidden h-full w-full flex items-center justify-center">
                        <strong className="text-sky-600 text-lg">
                          Intermodal Transport{" "}
                        </strong>
                      </div>
                      <div className="absolute my-rotate-y-180 backface-hidden h-full w-full bg-sky-600 shadow-lg rounded-md text-white flex items-center justify-center p-4 overflow-hidden">
                        PICKUPXPRESS offers intermodal transport solutions that
                        combine train cargo with other modes of transport, such
                        as trucks and ships. This allows for flexible
                        door-to-door delivery options, making it easier for
                        businesses to reach even remote destinations.
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="text-sm  cursor-pointer group perspective p-4 h-44 w-full">
                    <div className="relative duration-1000 preserve-3d group-hover:my-rotate-y-180 h-full bg-gray-100 rounded-lg shadow-lg">
                      <div className="text-center  absolute backface-hidden h-full w-full flex items-center justify-center">
                        <strong className="text-sky-600 text-lg">
                          Advanced Tracking{" "}
                        </strong>
                      </div>
                      <div className="absolute my-rotate-y-180 backface-hidden h-full w-full bg-sky-600 shadow-lg rounded-md text-white flex items-center justify-center p-4 overflow-hidden">
                        With PICKUPXPRESS, businesses can track their train
                        cargo shipments in real-time. This provides visibility
                        into the status and location of goods, offering peace of
                        mind and the ability to manage logistics proactively.
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="text-sm  cursor-pointer group perspective p-4 h-44 w-full">
                    <div className="relative duration-1000 preserve-3d group-hover:my-rotate-y-180 h-full bg-gray-100 rounded-lg shadow-lg">
                      <div className="text-center  absolute backface-hidden h-full w-full flex items-center justify-center">
                        <strong className="text-sky-600 text-lg">
                          Environmental Sustainability{" "}
                        </strong>
                      </div>
                      <div className="absolute my-rotate-y-180 backface-hidden h-full w-full bg-sky-600 shadow-lg rounded-md text-white flex items-center justify-center p-4 overflow-hidden">
                        By incorporating train cargo into their logistics
                        services, PICKUPXPRESS helps businesses reduce their
                        carbon footprint. Train cargo is a greener alternative
                        to road and air transport, supporting corporate
                        sustainability goals.
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="text-sm  cursor-pointer group perspective p-4 h-44 w-full">
                    <div className="relative duration-1000 preserve-3d group-hover:my-rotate-y-180 h-full bg-gray-100 rounded-lg shadow-lg">
                      <div className="text-center  absolute backface-hidden h-full w-full flex items-center justify-center">
                        <strong className="text-sky-600 text-lg">
                          Expert Handling{" "}
                        </strong>
                      </div>
                      <div className="absolute my-rotate-y-180 backface-hidden h-full w-full bg-sky-600 shadow-lg rounded-md text-white flex items-center justify-center p-4 overflow-hidden">
                        PICKUPXPRESS employs experienced logistics professionals
                        who are knowledgeable in managing train cargo
                        operations. From securing space on trains to handling
                        loading and unloading procedures, they ensure that
                        shipments are handled efficiently and safely.
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="text-sm  cursor-pointer group perspective p-4 h-44 w-full">
                    <div className="relative duration-1000 preserve-3d group-hover:my-rotate-y-180 h-full bg-gray-100 rounded-lg shadow-lg">
                      <div className="text-center  absolute backface-hidden h-full w-full flex items-center justify-center">
                        <strong className="text-sky-600 text-lg">
                          Cost Savings
                        </strong>
                      </div>
                      <div className="absolute my-rotate-y-180 backface-hidden h-full w-full bg-sky-600 shadow-lg rounded-md text-white flex items-center justify-center p-4 overflow-hidden">
                        Through efficient use of rail networks and optimized
                        logistics planning, PICKUPXPRESS helps businesses lower
                        their shipping costs. By maximizing train capacity and
                        selecting the most economical routes, they provide
                        cost-effective transport solutions.
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </section>

            <div className="mt-8 mb-8">
              <div className="bg-gray-100 shadow-lg rounded-lg p-6 card  max-w-full mt-6">
                <h3 className="text-2xl font-semibold mt-3 mb-6 text-gray-700 text-center">
                  Train Cargo and PICKUPXPRESS in Modern Logistics{" "}
                </h3>

                <ul className="space-y-4 text-gray-700">
                  <li className="flex items-start space-x-3">
                    <VscActivateBreakpoints className="text-cyan-600 w-10 h-10" />
                    <span>
                      Incorporating train cargo into the logistics strategy with
                      PICKUPXPRESS offers businesses a reliable, cost-effective,
                      and sustainable way to transport goods. Whether it’s
                      moving bulk commodities, containerized goods, or
                      specialized cargo, PICKUPXPRESS provides tailored train
                      cargo services to meet the unique needs of their clients.
                      With a focus on efficiency, safety, and environmental
                      sustainability, PICKUPXPRESS makes rail transport a viable
                      option for businesses looking to enhance their supply
                      chain performance.
                    </span>
                  </li>
                  <li className="flex items-start space-x-3">
                    <VscActivateBreakpoints className="text-cyan-600 w-10 h-10" />
                    <span>
                      In conclusion, train cargo is an essential mode of freight
                      transport that offers numerous advantages, including high
                      capacity, cost savings, and reduced environmental impact.
                      With PICKUPXPRESS, businesses can take full advantage of
                      train cargo services, benefiting from customized logistics
                      solutions, advanced tracking, and expert handling. By
                      choosing train cargo with PICKUPXPRESS, companies can
                      improve their shipping efficiency, reduce costs, and
                      contribute to a more sustainable future.
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <section className="bg-sky-600 rounded-lg shadow-lg p-6 text-white">
              <h2 className="text-3xl font-semibold mb-4">
                Contact PickupXpress Today
              </h2>
              <p className="text-lg mb-4">
                Ready to ship with{" "}
                <strong>PICKUPXPRESS Train Cargo Shipping ?</strong> Contact us
                today. Our team is here to help you find the best shipping
                solution for your business.
              </p>

              <a
                href="/Contactus"
                className="bg-white text-sky-600 px-4 py-2 rounded-lg font-semibold hover:bg-gray-200 transition duration-300"
              >
                Contact Us Today
              </a>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};
export default Traincargo;
