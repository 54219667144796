import React from "react";
import imp from "../assets/imp.gif";
import fast from "../assets/fast.png";
import time from "../assets/time.png";
import loop from "../assets/loop.png";
import small from "../assets/small.png";
import money from "../assets/money.png";
import ship from "../assets/ship.png";

const ExportImp = () => {
  return (
    <>
      <div className="bg-white py-12">
        <div className="container mx-auto px-6">
          <h2 className=" text-4xl font-bold text-center mb-14 text-gray-700">
            Import & Export Shipping Management{" "}
          </h2>

          <div className=" max-w-7xl mx-auto">
            <div className="flex flex-col lg:flex-row items-center">
              <div className=" card bg-white w-full lg:w-1/2 lg:h-96 flex justify-center text-center mb-6 lg:mb-0 rounded-lg shadow-lg">
                <img
                  src={imp}
                  alt="Express Parcels"
                  className="max-w-full max-h-full p-2 "
                />
              </div>

              <div className="w-full lg:w-1/2 lg:pl-12">
                <p className="text-gray-700 mb-6 text-lg">
                  Managing international shipments can be complex, but with
                  <strong>PickupXpress</strong>, it doesn't have to be. Our{" "}
                  <strong>Import & Export Shipping Management </strong>solutions
                  are designed to help businesses navigate the intricacies of
                  cross-border shipping. Whether you're importing raw materials
                  or exporting finished goods, PickupXpress ensures your
                  shipments move smoothly across borders, giving you peace of
                  mind and saving you time.
                </p>
              </div>
            </div>
            <section className="bg-white rounded-lg shadow-lg p-6 mb-8 mt-10 ">
              <div className=" py-12 bg-white-100 ">
                <div className="container mx-auto px-4">
                  <h1 className="text-3xl font-bold text-center mb-8 text-gray-700">
                    {" "}
                    Why Choose PickupXpress for Import & Export?{" "}
                  </h1>
                </div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 text-center">
                <div className="relative group">
                  <div className="card bg-white rounded-lg shadow-lg ">
                    <div className=" text-white p-4 inline-block">
                      <img src={fast} alt="Fast delivery" className="w-16" />{" "}
                      <p className="text-lg font-semibold mt-2 text-sky-600">
                        Seamless Customs Clearance{" "}
                      </p>
                    </div>
                  </div>
                  <span className="z-50 font-semibold hidden group-hover:block absolute top-28 left-0 right-0 text-gray-800 bg-gray-100 shadow-lg p-4 rounded-lg">
                    Navigating customs can be one of the most challenging
                    aspects of international shipping. With PickupXpress, we
                    take care of the complexities, ensuring your shipments clear
                    customs without unnecessary delays. Our experienced customs
                    brokers work closely with government agencies to ensure
                    compliance with all regulations.
                  </span>
                </div>
                <div className="relative group">
                  <div className="card bg-white rounded-lg shadow-lg ">
                    <div className=" text-white p-4 inline-block">
                      <img src={loop} alt="Fast delivery" className="w-16 " />{" "}
                      <p className="text-lg font-semibold mt-2 text-sky-600">
                        Global Network{" "}
                      </p>
                    </div>
                  </div>
                  <span className="z-50  font-semibold hidden group-hover:block absolute top-28 left-0 right-0 text-gray-800 bg-gray-100 shadow-lg p-4 rounded-lg">
                    With PickupXpress, you gain access to a vast global network
                    of shipping routes, carriers, and partners. This allows us
                    to provide you with the best possible shipping solutions,
                    whether you’re sending a package across the border or
                    halfway around the world.
                  </span>
                </div>
                <div className="relative group">
                  <div className="card bg-white rounded-lg shadow-lg ">
                    <div className=" text-white p-4 inline-block">
                      <img src={ship} alt="Fast delivery" className="w-16 " />{" "}
                      <p className="text-lg font-semibold mt-2 text-sky-600">
                        Door-to-Door Service{" "}
                      </p>
                    </div>
                  </div>
                  <span className="z-50  font-semibold hidden group-hover:block absolute top-28 left-0 right-0 text-gray-800 bg-gray-100 shadow-lg p-4 rounded-lg">
                    We provide comprehensive door-to-door services that handle
                    the entire process, from pick-up at your facility to
                    delivery at your customer’s location. PickupXpress takes
                    care of every detail, ensuring your goods are delivered
                    safely and on time.
                  </span>
                </div>
                <div className="relative group">
                  <div className="card bg-white rounded-lg shadow-lg ">
                    <div className=" text-white p-4 inline-block">
                      <img src={time} alt="Fast delivery" className="w-16 " />{" "}
                      <p className="text-lg font-semibold mt-2 text-sky-600">
                        Flexible Shipping Options{" "}
                      </p>
                    </div>
                  </div>
                  <span className="z-50  font-semibold hidden group-hover:block absolute top-28 left-0 right-0 text-gray-800 bg-gray-100 shadow-lg p-4 rounded-lg">
                    We understand that every shipment is unique. That’s why
                    PickupXpress offers a variety of shipping options, including
                    air, sea, and ground transport. Whether you need express
                    delivery or are looking for the most cost-effective method,
                    we have the solution to fit your needs.
                  </span>
                </div>
                <div className="relative group">
                  <div className="card bg-white rounded-lg shadow-lg ">
                    <div className=" text-white p-4 inline-block">
                      <img src={small} alt="Fast delivery" className="w-16 " />{" "}
                      <p className="text-lg font-semibold mt-2 text-sky-600">
                        Real-Time Tracking & Visibility
                      </p>
                    </div>
                  </div>
                  <span className="z-50  font-semibold hidden group-hover:block absolute top-28 left-0 right-0 text-gray-800 bg-gray-100 shadow-lg p-4 rounded-lg">
                    Keep a close eye on your shipments from the moment they
                    leave your warehouse until they reach their destination.
                    PickupXpress provides real-time tracking and updates,
                    ensuring you have complete visibility over your goods
                    throughout the shipping journey.
                  </span>
                </div>
                <div className="relative group">
                  <div className="card bg-white rounded-lg shadow-lg ">
                    <div className=" text-white p-4 inline-block">
                      <img src={money} alt="Fast delivery" className="w-16 " />{" "}
                      <p className="text-lg font-semibold mt-2 text-sky-600">
                        Cost-Effective Rates{" "}
                      </p>
                    </div>
                  </div>
                  <span className="z-50  font-semibold hidden group-hover:block absolute top-28 left-0 right-0 text-gray-800 bg-gray-100 shadow-lg p-4 rounded-lg">
                    Shipping internationally can be expensive, but PickupXpress
                    offers competitive pricing to help you manage costs
                    effectively. With our global partnerships and experience in
                    optimizing shipping routes, we ensure you get the best rates
                    without sacrificing service quality.
                  </span>
                </div>
              </div>
            </section>
            <section className="bg-white rounded-lg shadow-lg p-6 mb-8 mt-10 ">
              <div className="bg-white rounded-lg shadow-lg p-4 mb-8 mt-4">
                <h2 className="text-3xl font-bold text-center text-gray-700 mb-8">
                  Additional Benefits
                </h2>
                <ul className=" grid grid-cols-1 lg:grid-cols-2 gap-4">
                  <li>
                    <div className="text-sm  cursor-pointer group perspective p-4 h-44 w-full">
                      <div className="relative duration-1000 preserve-3d group-hover:my-rotate-y-180 h-full bg-gray-100 rounded-lg shadow-lg">
                        <div className="text-center  absolute backface-hidden h-full w-full flex items-center justify-center">
                          <strong className="text-sky-600 text-lg">
                            Customs Documentation Support{" "}
                          </strong>
                        </div>
                        <div className="absolute my-rotate-y-180 backface-hidden h-full w-full bg-sky-600 shadow-lg rounded-md text-white flex items-center justify-center p-4 overflow-hidden">
                          Get assistance with all necessary documentation,
                          including commercial invoices, certificates of origin,
                          and any other required paperwork. Our experts are here
                          to help make sure everything is accurate, minimizing
                          the risk of delays.
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="text-sm  cursor-pointer group perspective p-4 h-44 w-full">
                      <div className="relative duration-1000 preserve-3d group-hover:my-rotate-y-180 h-full bg-gray-100 rounded-lg shadow-lg">
                        <div className="text-center  absolute backface-hidden h-full w-full flex items-center justify-center">
                          <strong className="text-sky-600 text-lg">
                            Import & Export Compliance{" "}
                          </strong>
                        </div>
                        <div className="absolute my-rotate-y-180 backface-hidden h-full w-full bg-sky-600 shadow-lg rounded-md text-white flex items-center justify-center p-4 overflow-hidden">
                          Compliance with international trade laws is critical.
                          PickupXpress helps ensure that your shipments meet all
                          regulatory requirements, reducing the risk of fines
                          and penalties. We stay up to date with ever-changing
                          global regulations, so you don’t have to.
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="text-sm  cursor-pointer group perspective p-4 h-44 w-full">
                      <div className="relative duration-1000 preserve-3d group-hover:my-rotate-y-180 h-full bg-gray-100 rounded-lg shadow-lg">
                        <div className="text-center  absolute backface-hidden h-full w-full flex items-center justify-center">
                          <strong className="text-sky-600 text-lg">
                            Cargo Insurance{" "}
                          </strong>
                        </div>
                        <div className="absolute my-rotate-y-180 backface-hidden h-full w-full bg-sky-600 shadow-lg rounded-md text-white flex items-center justify-center p-4 overflow-hidden">
                          Protect your valuable shipments with PickupXpress’s
                          cargo insurance options. We offer comprehensive
                          coverage to ensure your goods are safeguarded against
                          loss or damage during transit.
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="text-sm  cursor-pointer group perspective p-4 h-44 w-full">
                      <div className="relative duration-1000 preserve-3d group-hover:my-rotate-y-180 h-full bg-gray-100 rounded-lg shadow-lg">
                        <div className="text-center  absolute backface-hidden h-full w-full flex items-center justify-center">
                          <strong className="text-sky-600 text-lg">
                            Consolidation Services{" "}
                          </strong>
                        </div>
                        <div className="absolute my-rotate-y-180 backface-hidden h-full w-full bg-sky-600 shadow-lg rounded-md text-white flex items-center justify-center p-4 overflow-hidden">
                          For businesses that ship regularly, PickupXpress
                          offers consolidation services to help you save on
                          shipping costs. By grouping your shipments, we reduce
                          shipping expenses while ensuring all goods reach their
                          destination promptly.{" "}
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="mt-8 ">
                <h2 className="text-3xl font-semibold text-gray-700 mb-4 text-center">
                  Industries we serve
                </h2>
                <p className="text-gray-700 mt-4 text-base font-semibold">
                  From manufacturing and retail to technology and
                  pharmaceuticals, <strong>PickupXpress</strong> works with
                  businesses of all types and sizes to manage their
                  international shipping needs. Our solutions are scalable and
                  adaptable, ensuring we can meet the unique requirements of
                  your business.
                </p>
              </div>
            </section>

            <section className="bg-sky-600 rounded-lg shadow-lg p-6 text-white">
              <h2 className="text-3xl font-semibold mb-4">
                Contact PickupXpress Today
              </h2>
              <p className="text-lg mb-4">
                When it comes to international trade, you need a reliable
                partner who understands the complexities of global logistics.
                PickupXpress offers comprehensive{" "}
                <strong>import and export shipping management solutions</strong>{" "}
                that simplify the process and give you peace of mind. Contact us
                today to learn how we can help streamline your international
                shipping operations.
              </p>

              <a
                href="/Contactus"
                className="bg-white text-sky-600 px-4 py-2 rounded-lg font-semibold hover:bg-gray-200 transition duration-300"
              >
                Contact Us Today
              </a>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};
export default ExportImp;
