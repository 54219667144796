import React from "react";
import { VscActivateBreakpoints } from "react-icons/vsc";

const Financial = () => {
  const data = [
    {
      sno: 1,
      document: "Indepedent Auditor's Report",
      action: "Download",
      link: "/Documents/independent_auditors_report.pdf",
    },
    {
      sno: 2,
      document: "List of Share Holders",
      action: "Download",
      link: "/Documents/shareholder_list.pdf",
    },
    {
      sno: 3,
      document: "Koi Report",
      action: "Download",
      link: "/Documents/independent_auditors_report.pdf",
    },
  ];
  return (
    <div
      className="-z-50 relative block w-full mt-3 max-w-screen-xl px-4  mx-auto text-black bg-white border shadow-md 
  rounded-xl border-white/80 bg-opacity-80 backdrop-blur-2xl backdrop-saturate-200 lg:px-8 lg:py-4 py-12"
    >
      <div className="container mx-auto px-6">
        <h2 className="py-8 text-4xl font-bold text-center mb-6 text-sky-600">
          Financial Reports
        </h2>

        <div>
          <div>
            <p className="mb-4 text-lg text-gray-700 text-justify mt-6">
              At PickupXpress, we are committed to transparency and
              accountability. Our financial reports provide comprehensive
              insights into our company’s financial performance and strategic
              initiatives. By offering detailed quarterly and annual reports, we
              aim to keep our stakeholders, partners, and clients informed about
              our growth and financial health.
            </p>
            <p className="mb-4 font-semibold text-lg text-gray-700 text-justify mt-2">
              Explore our latest financial reports to see how we are creating
              value and driving results in a competitive market.
            </p>

            <div className=" mt-6 bg-gray-100 shadow-lg rounded-lg p-4 card mb-4">
              <h3 className="text-2xl font-bold mb-6 text-gray-800 card mt-4 ">
                What You’ll Find{" "}
              </h3>
              <ul className="space-y-4 text-gray-700">
                <li className="flex items-start space-x-3 gap-3">
                  <VscActivateBreakpoints className="text-cyan-600 w-6 h-6" />
                  <p>
                    <strong>Annual Reports : </strong> An overview of the
                    company’s performance over the past year, highlighting key
                    financial statements such as revenue, expenses, and profit
                    margins.
                  </p>
                </li>
                <li className="flex items-start space-x-3 gap-3">
                  <VscActivateBreakpoints className="text-cyan-600 w-6 h-6" />
                  <p>
                    {" "}
                    <strong> Quarterly Reports : </strong> Periodic updates that
                    provide detailed insights into our financial progress and
                    important metrics for each quarter.
                  </p>
                </li>
                <li className="flex items-start space-x-3 gap-3">
                  <VscActivateBreakpoints className="text-cyan-600 w-6 h-6" />
                  <p>
                    {" "}
                    <strong> Balance Sheets : </strong> A breakdown of assets,
                    liabilities, and equity, giving you a clear picture of our
                    financial position.{" "}
                  </p>
                </li>
                <li className="flex items-start space-x-3 gap-3">
                  <VscActivateBreakpoints className="text-cyan-600 w-6 h-6" />
                  <p>
                    {" "}
                    <strong> Shareholder Reports : </strong> Information
                    tailored for our investors, showcasing dividend payments,
                    earnings per share, and shareholder equity.
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <div className="flex flex-col space-y-8">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h4 className="text-xl font-semibold text-gray-800">
                Download Our Latest Reports
              </h4>
              <p className="text-gray-800 mt-2">
                You can easily access and download our reports by clicking on
                the links below. All reports are available in PDF format for
                easy viewing.
              </p>
              <div>
                <table className="min-w-full bg-gray-100 p-6 mt-4">
                  <thead>
                    <tr className="w-full bg-gray-300 text-left shadow-lg">
                      <th className="py-2 px-4 border-b">S.No.</th>
                      <th className="py-2 px-4 border-b">Document name</th>
                      <th className="py-2 px-4 border-b">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((person, index) => (
                      <tr key={index}>
                        <td className="py-2 px-4 border-b">{person.sno}</td>
                        <td className="py-2 px-4 border-b">
                          {person.document}
                        </td>
                        <td className="py-2 px-4 border-b hover:bg-red-700">
                          <a
                            href={person.link}
                            download
                            className="text-black font-semibold hover:text-sky-600 cursor-pointer"
                          >
                            {person.action}
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Financial;
